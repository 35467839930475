import { useState, useEffect } from "react";
import { Row, Col, Card, Typography, Divider, Tag, List, Input, Space, Skeleton as Skel, Button, Result } from "antd";
import TotalDashboard from "./DashboardTotal";
import Paragraph from "antd/lib/skeleton/Paragraph";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import axios from "axios";
import API from "../api/url";
import InfiniteScroll from 'react-infinite-scroll-component';

const Kegiatan = () => {
  const [data, setData] = useState([]);
  const [dataFilter, setDataFilter] = useState([]);
  const [loading2, setLoading2] = useState(false);
  const [maxPage, setMaxPage] = useState(1);
  const [detailSub, setDetailSub] = useState(null);

  const { Search } = Input;
  const { Title } = Typography;

  const onSearchList = value => {
    if(value != ''){
      const filteredData = data.filter(entry => entry.nama.toLowerCase().includes(value.toLowerCase()));
      setDataFilter(filteredData);
    } else {
      setDataFilter(data);
    }
  };

  const detailSubKegiatan = async (kode) => {
    await axios({
      method: "POST",
      url: `${API.DISPERKIM_UTAMA}data/sub-kegiatan-by-kode`,
      data: {
        kode: kode,
      },
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
      }
    })
    .then((response) => {
      const result = response.data.data;
      setDetailSub(result);
    })
    .catch(error => {
      console.log(`Error: ${error}`);
      setDetailSub(null);
    });
  };

  const onClickList = async (kode) => {
    await detailSubKegiatan(kode);
  };

  const loadMoreData = async () => {
    if (loading2) {
      return;
    }
    setLoading2(true);
    await axios({
      method: "POST",
      // url: `${API.DISPERKIM_UTAMA}data/sub-kegiatan?page=${page}`,
      url: `${API.DISPERKIM_UTAMA}data/sub-kegiatan`,
      data: {
        kegiatan_id: null,
        program_id: null
      },
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
      }
    })
    .then((response) => {
      const result = response.data.data;
      // setData([...data, ...result]);
      setData(result);
      setDataFilter(result);
      setLoading2(false);
      // setPage(page + 1);
      // if(maxPage == 1) {
      //   setMaxPage(response.data.data.total);
      // }
    })
    .catch(error => {
      console.log(`Error: ${error}`);
      setLoading2(false);
      // setPage(1);
      // setMaxPage(1);
    });
  };

  useEffect(() => {
    loadMoreData();
  }, []);

  return (
    <>
      <TotalDashboard />
      <Row gutter={[24, 0]}>
        <Col xs={24} md={12} sm={24} lg={12} xl={14} className="mb-24">
          <Card bordered={false} className="criclebox h-full">
            <Row gutter>
              <Col
                xs={24}
                md={24}
                sm={24}
                lg={24}
                xl={24}
                className="mobile-24"
              >
                <div className="project-ant">
                  <div>
                  <Title level={5}>Daftar Sub Kegiatan</Title>
                    Sumber : <span className="red">Disperkim Sumatera Selatan</span>
                    </div>
                  <div className="ant-filtertabs">
                    <div className="antd-pro-pages-dashboard-analysis-style-salesExtra">
                      <Space>
                        <Search
                          placeholder="Search"
                          onChange={(e) => onSearchList(e.target.value)}
                          enterButton
                        />
                      </Space>
                    </div>
                  </div>
                </div>
                <br />
                <div
                  id="scrollableDiv"
                  style={{
                    height: 400,
                    overflow: "auto",
                    padding: "0 16px",
                    border: "1px solid rgba(140, 140, 140, 0.35)",
                  }}
                >
                  <InfiniteScroll
                    dataLength={data.length}
                    next={loadMoreData}
                    hasMore={data.length <= maxPage}
                    loader={
                      <Skel
                        avatar
                        paragraph={{
                          rows: 1,
                        }}
                        active
                      />
                    }
                    endMessage={
                      <Divider plain>It is all, nothing more 🤐</Divider>
                    }
                    scrollableTarget="scrollableDiv"
                  >
                    <List
                      dataSource={dataFilter}
                      size="large"
                      itemLayout="vertical"
                      pagination={{
                        onChange: (page) => {
                          // console.log(page);
                        },
                        pageSize: 3,
                      }}
                      renderItem={(item, index) => (
                        <List.Item key={index}>
                          <List.Item.Meta
                            title={item.nama}
                            description={item.kinerja}
                          />
                          <div>
                            <Button
                              type="primary"
                              size={"small"}
                              onClick={() => onClickList(item.kode)}
                              style={{ fontWeight: "bold", color: "white" }}
                            >
                              {item.kode_display}
                            </Button>
                          </div>
                        </List.Item>
                      )}
                    />
                  </InfiniteScroll>
                </div>
              </Col>
              {/* <Col
                  xs={24}
                  md={12}
                  sm={24}
                  lg={12}
                  xl={10}
                  className="col-img"
                >
                  <div className="ant-cret text-right">
                    <img src={card} alt="" className="border10" />
                  </div>
                </Col> */}
            </Row>
          </Card>
        </Col>

        <Col xs={24} md={12} sm={24} lg={12} xl={10} className="mb-24">
          <Card bordered={false} className="criclebox card-info-2 h-full">
            {detailSub == null ? 
              <Result
                status="404"
                title="404"
                subTitle="Data detail dari Sub Kegiatan Kosong. Silahkan pilih salah satu sub kegiatan disamping"
                // extra={<Button type="primary">Ok</Button>}
              />
            : 
              <div className="gradent h-full col-content">
                <div className="card-content">
                  <p>
                    <Tag color="magenta">{detailSub.kode_display}</Tag>
                  </p>
                  <br />
                  <Divider
                    orientation="left"
                    style={{ color: "white", borderTopColor: "#fff" }}
                  >
                    Data Program
                  </Divider>
                  <p>{detailSub.program.nama}</p>
                  <p>{detailSub.program.kode_display}</p>
                  <br />
                  <Divider
                    orientation="left"
                    style={{ color: "white", borderTopColor: "#fff" }}
                  >
                    Data Kegiatan
                  </Divider>
                  <p>{detailSub.kegiatan.nama}</p>
                  <p>{detailSub.kegiatan.kode_display}</p>
                </div>
                {/* <div className="card-footer">
                    <a className="icon-move-right" href="#pablo">
                      Read More
                      <RightOutlined />
                    </a>
                  </div> */}
              </div>
            }
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Kegiatan;
