import { useEffect, useState } from "react";
import axios from "axios";
import API from "../api/url";
import { PerkimLogin } from "../resource/res";
import { Table, Tag } from "antd";

const RealEstat = () => {
  const [realEstat, setRealEstat] = useState([]);

	const Tagged = (text, key) => 
		{
			if (text == "YA") {
				return (
					<Tag color="green" key={`${key}_${text}`}>
						{text}
					</Tag>
				)
			} else {
				return (
					<Tag color="volcano" key={`${key}_${text}`}>
						{text}
					</Tag>
				)
			}
		}
	;

	const columns = [
    {
      title: 'PERUSAHAAN',
      dataIndex: 'perusahaan',
      key: 'perusahaan',
    },
    {
      title: 'RESIDENSIAL',
      dataIndex: 'residensial',
      key: 'residensial',
			render: (text) => Tagged(text, "residensial"),
    },
    {
      title: 'APARTEMENT',
      dataIndex: 'apartemen',
      key: 'apartemen',
			render: (text) => Tagged(text, "apartemen"),
    },
    {
      title: 'RUKO',
      dataIndex: 'ruko',
      key: 'ruko',
			render: (text) => Tagged(text, "ruko"),
    },
    {
      title: 'PERKANTORAN',
      dataIndex: 'perkantoran',
      key: 'perkantoran',
			render: (text) => Tagged(text, "perkantoran"),
    },
    {
      title: 'KAWASAN INDUSTRI',
      dataIndex: 'kawasan_industri',
      key: 'kawasan_industri',
			render: (text) => Tagged(text, "kawasan_industri"),
    },
		{
			title: 'LAPANGAN GOLF',
			dataIndex: 'lapangan_golf',
			key: 'lapangan_golf',
			render: (text) => Tagged(text, "lapangan_golf"),
		},
		{
			title: 'HOTEL',
			dataIndex: 'hotel',
			key: 'hotel',
			render: (text) => Tagged(text, "hotel"),
		},
		{
			title: 'PUSAT PERBELANJAAN',
			dataIndex: 'pusat_perbelanjaan',
			key: 'pusat_perbelanjaan',
			render: (text) => Tagged(text, "pusat_perbelanjaan"),
		},
		{
			title: 'WAHANA HIBURAN',
			dataIndex: 'wahana_hiburan',
			key: 'wahana_hiburan',
			render: (text) => Tagged(text, "wahana_hiburan"),
		},
  ];

	const getRealEstat = async () => {
    await axios({
      method: "get",
      url: `${API.JS_PERKIM}pengembang-realestat`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("jstoken")}`,
      },
    }).then((response) => {
      const { data } = response.data;
			setRealEstat(data);
    }).catch((error) => {
      console.log(error.response);
      if (error.response.status == 401) {
        PerkimLogin();
				getRealEstat();
      }
    });
  };

	useEffect(() => {
		getRealEstat();
	}, []);

	return (
		<>
			<Table
        key={`realestat`}
        showHeader={true} 
        bordered={true} 
        columns={columns} 
        title={() => <h3>Pengembang RealEstat</h3>}
        dataSource={
          realEstat.map((item, index) => ({
            key: index,
						perusahaan: item.perusahaan,
						residensial: item.residensial,
						apartemen: item.apartemen,
						ruko: item.ruko,
						perkantoran: item.perkantoran,
						kawasan_industri: item.kawasan_industri,
						lapangan_golf: item.lapangan_golf,
						hotel: item.hotel,
						pusat_perbelanjaan: item.pusat_perbelanjaan,
						wahana_hiburan: item.wahana_hiburan,
          }))
        } 
      />
		</>
	)
}

export default RealEstat;