import { Switch, Route, Redirect } from "react-router-dom";
import Home from "./pages/Home";
import Tables from "./pages/Tables";
import Billing from "./pages/Billing";
import Rtl from "./pages/Rtl";
import Profile from "./pages/Profile";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import Main from "./components/layout/Main";
import Koneksi from "./pages/Koneksi";
import RtlhBacklog from "./pages/RtlhBacklog";
import RekapPerum from "./pages/RekapPerum";
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import Flpp from "./pages/Flpp";
import Perumahan from "./pages/Perumahan";
import Kegiatan from "./pages/Kegiatan";
import SupplyRumah from "./pages/SupplyRumah";
import RealEstat from "./pages/RealEstat";
import RealisasiKpr from "./pages/RealisasiKpr";
import BacklogRumah from "./pages/BacklogRumah";
import HousingPage from "./pages/HousingPage";

function App() {
  return (
    <div className="App">
      <Switch>
        {/* <Route path="/sign-up" exact component={SignUp} /> */}
        <Route path="/sign-in" exact component={SignIn} />
        <Main>
          <Route exact path="/dashboard" component={Home} />
          <Route exact path="/tables" component={Tables} />
          <Route exact path="/billing" component={Billing} />
          <Route exact path="/rtl" component={Rtl} />
          {/* <Route exact path="/profile" component={Profile} /> */}
          <Route exact path="/koneksi" component={Koneksi} />
          <Route exact path="/rtlh-backlog" component={RtlhBacklog} />
          <Route exact path="/rekap-perum" component={RekapPerum} />
          <Route exact path="/data-flpp" component={Flpp} />
          <Route exact path="/perumahan" component={Perumahan} />
          <Route exact path="/kegiatan" component={Kegiatan} />
          <Route exact path="/supply-rumah" component={SupplyRumah} />
          <Route exact path="/real-estat" component={RealEstat} />
          <Route exact path="/realisasi-kpr" component={RealisasiKpr} />
          <Route exact path="/backlog-rumah" component={BacklogRumah} />
          <Route exact path="/data-housing-clinic" component={HousingPage} />
          <Redirect from="*" to="/dashboard" />
        </Main>
      </Switch>
    </div>
  );
}

export default App;
