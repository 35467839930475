import { useEffect, useState } from "react";
import axios from "axios";
import API from "../api/url";
import { PerkimLogin } from "../resource/res";
import { Table, Tag, Tabs, Empty, List, Card, Typography, Row, Col, Badge } from "antd";
import { FormatRupiah } from "@arismun/format-rupiah";
import { SolutionOutlined } from '@ant-design/icons';

const BacklogRumah = () => {
  const [kepemilikan, setKepemilikan] = useState([]);
  const [kualitas, setKualitas] = useState([]);

  const { Title } = Typography;
  const getKepemilikan = async () => {
    await axios({
      method: "get",
      url: `${API.JS_PERKIM}backlog-kepemilikan-rumah`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("jstoken")}`,
      },
    })
      .then((response) => {
        const { data } = response.data;
        setKepemilikan(data);
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response.status == 401) {
          PerkimLogin();
          setKepemilikan();
        }
      });
  };

  const getKualitas = async () => {
    await axios({
      method: "get",
      url: `${API.JS_PERKIM}backlog-kualitas-rumah`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("jstoken")}`,
      },
    })
      .then((response) => {
        const { data } = response.data;
        setKualitas(data);
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response.status == 401) {
          PerkimLogin();
          setKualitas();
        }
      });
  };

  useEffect(() => {
    getKepemilikan();
    getKualitas();
  }, []);

  if (kepemilikan.length == 0 || kualitas.length == 0) {
    return <Empty />;
  }
  return (
    <>
      <Tabs
        defaultActiveKey="kepemilikan"
        type="card"
      >
        <Tabs.TabPane tab="Kepemilikan Rumah" key="kepemilikan">
          <Row className="rowgap-vbox" gutter={[24, 0]}>
            {kepemilikan.map((item, index) => {
              return (
                <Col
                  key={index}
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  className="mb-24"
                >
                  <Badge.Ribbon text="Sumber: HREIS" color="cyan">
                    <Card bordered={true}>
                      <div className="number">
                        <Row align="middle" gutter={[24, 0]}>
                          <Col xs={24}>
                            <Title level={2} bodyStyle={{ textAlign: "left" }}>
                              {item.tahun}
                            </Title>
                            <Row align="middle" gutter={[24, 0]}>
                              <Col xs={24}>
                                <Card
                                  type="inner"
                                  title="Kepemilikan Rumah"
                                  bordered={true}
                                  style={{
                                    boxShadow: "0 0 0 0",
                                  }}
                                  headStyle={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  <List
                                    size="small"
                                    bordered
                                  >
                                    <List.Item>{"< 1.5 juta: "}<Tag color="volcano">{item.v_kurang_1_5_juta}</Tag></List.Item>
                                    <List.Item>{"1.5 - 2.2 juta: "}<Tag color="volcano">{item.v_1_5_sd_2_2_juta}</Tag></List.Item>
                                    <List.Item>{"2.2 - 2.8 juta: "}<Tag color="volcano">{item.v_2_2_sd_2_8_juta}</Tag></List.Item>
                                    <List.Item>{"2.8 - 3.4 juta: "}<Tag color="volcano">{item.v_2_8_sd_3_4_juta}</Tag></List.Item>
                                    <List.Item>{"3.4 - 4.1 juta: "}<Tag color="volcano">{item.v_3_4_sd_4_1_juta}</Tag></List.Item>
                                    <List.Item>{"4.1 - 5 juta: "}<Tag color="volcano">{item.v_4_1_sd_5_juta}</Tag></List.Item>
                                    <List.Item>{"5 - 6.2 juta: "}<Tag color="volcano">{item.v_5_sd_6_2_juta}</Tag></List.Item>
                                    <List.Item>{"6.2 - 8.1 juta: "}<Tag color="volcano">{item.v_6_2_sd_8_1_juta}</Tag></List.Item>
                                    <List.Item>{"8.1 - 12.7 juta: "}<Tag color="volcano">{item.v_8_1_sd_12_7_juta}</Tag></List.Item>
                                    <List.Item>{"> 12.7 juta: "}<Tag color="volcano">{item.v_lebih_12_7_juta}</Tag></List.Item>
                                    <List.Item>{"Total: "}<Tag color="cyan">{item.total}</Tag></List.Item>
                                  </List>
                                </Card>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  </Badge.Ribbon>
                </Col>
              );
            })}
          </Row>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Kualitas Rumah" key="kualitas">
        <Row className="rowgap-vbox" gutter={[24, 0]}>
            {kualitas.map((item, index) => {
              return (
                <Col
                  key={index}
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  className="mb-24"
                >
                  <Badge.Ribbon text="Sumber: HREIS" color="cyan">
                    <Card bordered={true}>
                      <div className="number">
                        <Row align="middle" gutter={[24, 0]}>
                          <Col xs={24}>
                            <Title level={2} bodyStyle={{ textAlign: "left" }}>
                              {item.tahun}
                            </Title>
                            <Row align="middle" gutter={[24, 0]}>
                              <Col xs={24}>
                                <Card
                                  type="inner"
                                  title="Kualitas Rumah"
                                  bordered={true}
                                  style={{
                                    boxShadow: "0 0 0 0",
                                  }}
                                  headStyle={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  <List
                                    size="small"
                                    bordered
                                  >
                                    <List.Item>{"< 1.5 juta: "}<Tag color="volcano">{item.v_kurang_1_5_juta}</Tag></List.Item>
                                    <List.Item>{"1.5 - 2.2 juta: "}<Tag color="volcano">{item.v_1_5_sd_2_2_juta}</Tag></List.Item>
                                    <List.Item>{"2.2 - 2.8 juta: "}<Tag color="volcano">{item.v_2_2_sd_2_8_juta}</Tag></List.Item>
                                    <List.Item>{"2.8 - 3.4 juta: "}<Tag color="volcano">{item.v_2_8_sd_3_4_juta}</Tag></List.Item>
                                    <List.Item>{"3.4 - 4.1 juta: "}<Tag color="volcano">{item.v_3_4_sd_4_1_juta}</Tag></List.Item>
                                    <List.Item>{"4.1 - 5 juta: "}<Tag color="volcano">{item.v_4_1_sd_5_juta}</Tag></List.Item>
                                    <List.Item>{"5 - 6.2 juta: "}<Tag color="volcano">{item.v_5_sd_6_2_juta}</Tag></List.Item>
                                    <List.Item>{"6.2 - 8.1 juta: "}<Tag color="volcano">{item.v_6_2_sd_8_1_juta}</Tag></List.Item>
                                    <List.Item>{"8.1 - 12.7 juta: "}<Tag color="volcano">{item.v_8_1_sd_12_7_juta}</Tag></List.Item>
                                    <List.Item>{"> 12.7 juta: "}<Tag color="volcano">{item.v_lebih_12_7_juta}</Tag></List.Item>
                                    <List.Item>{"Total: "}<Tag color="cyan">{item.total}</Tag></List.Item>
                                  </List>
                                </Card>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  </Badge.Ribbon>
                </Col>
              );
            })}
          </Row>
        </Tabs.TabPane>
      </Tabs>
    </>
  );
};

export default BacklogRumah;
