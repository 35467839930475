import { useState, useEffect } from "react";
import { Menu, Button, message, Modal, Row, Col } from "antd";
import { LayoutOutlined } from '@ant-design/icons';
import Title from "antd/lib/typography/Title";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/images/logo.jpg";
import { useHistory, Redirect } from "react-router-dom";
import API from "../../api/url";
import axios from "axios";

function Sidenav({ color }) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");

  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    console.log("OPEN");
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState('right');
  const showDrawer = () => {
    setOpen(true);
  };
  const onChange = (e) => {
    setPlacement(e.target.value);
  };
  const onClose = () => {
    setOpen(false);
  };

  const dashboard = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z"
        fill={color}
      ></path>
      <path
        d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z"
        fill={color}
      ></path>
      <path
        d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const tables = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M9 2C8.44772 2 8 2.44772 8 3C8 3.55228 8.44772 4 9 4H11C11.5523 4 12 3.55228 12 3C12 2.44772 11.5523 2 11 2H9Z"
        fill={color}
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 5C4 3.89543 4.89543 3 6 3C6 4.65685 7.34315 6 9 6H11C12.6569 6 14 4.65685 14 3C15.1046 3 16 3.89543 16 5V16C16 17.1046 15.1046 18 14 18H6C4.89543 18 4 17.1046 4 16V5ZM7 9C6.44772 9 6 9.44772 6 10C6 10.5523 6.44772 11 7 11H7.01C7.56228 11 8.01 10.5523 8.01 10C8.01 9.44772 7.56228 9 7.01 9H7ZM10 9C9.44772 9 9 9.44772 9 10C9 10.5523 9.44772 11 10 11H13C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9H10ZM7 13C6.44772 13 6 13.4477 6 14C6 14.5523 6.44772 15 7 15H7.01C7.56228 15 8.01 14.5523 8.01 14C8.01 13.4477 7.56228 13 7.01 13H7ZM10 13C9.44772 13 9 13.4477 9 14C9 14.5523 9.44772 15 10 15H13C13.5523 15 14 14.5523 14 14C14 13.4477 13.5523 13 13 13H10Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const billing = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M4 4C2.89543 4 2 4.89543 2 6V7H18V6C18 4.89543 17.1046 4 16 4H4Z"
        fill={color}
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 9H2V14C2 15.1046 2.89543 16 4 16H16C17.1046 16 18 15.1046 18 14V9ZM4 13C4 12.4477 4.44772 12 5 12H6C6.55228 12 7 12.4477 7 13C7 13.5523 6.55228 14 6 14H5C4.44772 14 4 13.5523 4 13ZM9 12C8.44772 12 8 12.4477 8 13C8 13.5523 8.44772 14 9 14H10C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12H9Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const koneksi = [
    <svg 
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      id="Isolation_Mode" data-name="Isolation Mode"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      {/* <path 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19,1H5A5.006,5.006,0,0,0,0,6V18a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V6A5.006,5.006,0,0,0,19,1ZM5,3H19a3,3,0,0,1,2.78,1.887l-7.658,7.659a3.007,3.007,0,0,1-4.244,0L2.22,4.887A3,3,0,0,1,5,3ZM19,21H5a3,3,0,0,1-3-3V7.5L8.464,13.96a5.007,5.007,0,0,0,7.072,0L22,7.5V18A3,3,0,0,1,19,21Z"
        fill={color}
      ></path> */}
      <path 
        d="M7.253,24A7.251,7.251,0,0,1,2.124,11.62L6.191,7.55,8.313,9.67,4.246,13.741a4.253,4.253,0,1,0,6.016,6.014l4.068-4.068,2.121,2.121-4.068,4.068A7.229,7.229,0,0,1,7.253,24Z"
        fill={color}
      />
      <path 
        d="M17.808,16.452l-2.121-2.121,4.068-4.068A4.254,4.254,0,0,0,13.74,4.246L9.669,8.314,7.548,6.192l4.071-4.068a7.254,7.254,0,0,1,10.257,10.26Z"
        fill={color}
      />
      <rect 
        x="7.204" 
        y="10.501" 
        width="9.591" 
        height="3" 
        transform="translate(-4.971 12) rotate(-45)"
      />
    </svg>,
  ];

  const rtl = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 6C3 4.34315 4.34315 3 6 3H16C16.3788 3 16.725 3.214 16.8944 3.55279C17.0638 3.89157 17.0273 4.29698 16.8 4.6L14.25 8L16.8 11.4C17.0273 11.703 17.0638 12.1084 16.8944 12.4472C16.725 12.786 16.3788 13 16 13H6C5.44772 13 5 13.4477 5 14V17C5 17.5523 4.55228 18 4 18C3.44772 18 3 17.5523 3 17V6Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const profile = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const signin = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 2C5.44772 2 5 2.44772 5 3V4H4C2.89543 4 2 4.89543 2 6V16C2 17.1046 2.89543 18 4 18H16C17.1046 18 18 17.1046 18 16V6C18 4.89543 17.1046 4 16 4H15V3C15 2.44772 14.5523 2 14 2C13.4477 2 13 2.44772 13 3V4H7V3C7 2.44772 6.55228 2 6 2ZM6 7C5.44772 7 5 7.44772 5 8C5 8.55228 5.44772 9 6 9H14C14.5523 9 15 8.55228 15 8C15 7.44772 14.5523 7 14 7H6Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const signup = [
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      key={0}
    >
      <path
        d="M0,2A2,2,0,0,1,2,0H8a2,2,0,0,1,2,2V8a2,2,0,0,1-2,2H2A2,2,0,0,1,0,8Z"
        transform="translate(4 4)"
        fill={color}
      />
      <path
        d="M2,0A2,2,0,0,0,0,2V8a2,2,0,0,0,2,2V4A2,2,0,0,1,4,2h6A2,2,0,0,0,8,0Z"
        fill={color}
      />
    </svg>,
  ];

  const logout = async (token) => {
    await axios({
      url: `${API.JS_PERKIM}logout-new`,
      method: "POST",
      headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': "application/json",
        'Content-Type': "application/json"
      }
    })
    .then((response) => {
      try {
        if(response.data.message === "success") {
          localStorage.setItem("token", "");
          localStorage.setItem("isLogin", false);
          localStorage.removeItem("user");
          history.replace("/sign-in");
        }
      } catch (curError) {
        if (axios.isCancel(curError)) {
            return false;
        }
        // error handling code 
      }
    })
    .catch((error) => {
      if (error.response) {
        message.error(error.response.data.message, 5);
        if(error.response.data.message === "Unauthenticated.") {
          localStorage.setItem("token", "");
          localStorage.setItem("isLogin", false);
          localStorage.removeItem("user");
          history.replace("/sign-in");
        }
      } else if (error.request) {
        message.error('Network Error');
        localStorage.setItem("token", "");
        localStorage.setItem("isLogin", false);
        localStorage.removeItem("user");
        history.replace("/sign-in");
      } else {
        message.error(error.message);
        localStorage.setItem("token", "");
        localStorage.setItem("isLogin", false);
        localStorage.removeItem("user");
        history.replace("/sign-in");
      }
    })
  };

  const handleLogout = async (e) => {
    setLoading(true);
    e.preventDefault();
    const token = localStorage.getItem("token");
    await logout(token);
    setLoading(false);
    // setTimeout(() => {
    //   setLoading(false);
    // },3000);
  };

  let user = null;
  useEffect(() => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    return () => {
      source.cancel("axios request cancelled");
    };
  }, []);
  if(localStorage.getItem("user") !== "") {
    user = JSON.parse(localStorage.getItem("user"));
  }
  return (
    <>
      <Modal onCancel={handleOk} title="Menu" visible={isModalOpen} footer={null} width={400}>
        <Menu key="menu_2" theme="light" mode="inline">
          <Menu.Item key="rtlh-backlog" icon={ <LayoutOutlined /> }>
            <NavLink to="/rtlh-backlog" onClick={handleOk}>
              <span className="label">RTLH & Backlog</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="rekap-perum" icon={ <LayoutOutlined /> }>
            <NavLink to="/rekap-perum" onClick={handleOk}>
              <span className="label">Rekap Perum</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="data-flpp" icon={ <LayoutOutlined /> }>
            <NavLink to="/data-flpp" onClick={handleOk}>
              <span className="label">Data FLPP</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="perumahan" icon={ <LayoutOutlined /> }>
            <NavLink to="/perumahan" onClick={handleOk}>
              <span className="label">Perumahan</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="kegiatan" icon={ <LayoutOutlined /> }>
            <NavLink to="/kegiatan" onClick={handleOk}>
              <span className="label">Kegiatan</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="supply-rumah" icon={ <LayoutOutlined /> }>
            <NavLink to="/supply-rumah" onClick={handleOk}>
              <span className="label">Supply Rumah</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="real-estat" icon={ <LayoutOutlined /> }>
            <NavLink to="/real-estat" onClick={handleOk}>
              <span className="label">Real Estat</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="realisasi-kpr" icon={ <LayoutOutlined /> }>
            <NavLink to="/realisasi-kpr" onClick={handleOk}>
              <span className="label">Realisasi KPR</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="backlog-rumah" icon={ <LayoutOutlined /> }>
            <NavLink to="/backlog-rumah" onClick={handleOk}>
              <span className="label">Backlog Rumah</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="data-housing-clinic" icon={ <LayoutOutlined /> }>
            <NavLink to="/data-housing-clinic" onClick={handleOk}>
              <span className="label">Data Housing Clinic</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="koneksi" icon={ <LayoutOutlined /> }>
            <NavLink to="/koneksi" onClick={handleOk}>
              <span className="label">Koneksi</span>
            </NavLink>
          </Menu.Item>
        </Menu>
      </Modal>
      <div className="brand">
        <img src={logo} alt="" />
        <span>Jaringan Simpul</span>
      </div>
      <hr />
      <Menu key="1" theme="light" mode="inline">
        <Menu.Item key="1">
          <NavLink to="/dashboard">
            <span
              className="icon"
            >
              {dashboard}
            </span>
            <span className="label">Dashboard</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="10">
          <a href={void(0)} onClick={showModal}>
            <span
              className="icon"
              style={{
                background: page === "#" ? color : "",
              }}
            >
              {dashboard}
            </span>
            <span className="label">Menu</span>
          </a>
        </Menu.Item>
        {/* <Menu.Item key="2">
          <NavLink to="/tables">
            <span
              className="icon"
              style={{
                background: page === "tables" ? color : "",
              }}
            >
              {tables}
            </span>
            <span className="label">Tables</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="3">
          <NavLink to="/billing">
            <span
              className="icon"
              style={{
                background: page === "billing" ? color : "",
              }}
            >
              {billing}
            </span>
            <span className="label">Billing</span>
          </NavLink>
        </Menu.Item> */}
        {/* <Menu.Item key="4">
          <NavLink to="/rtl">
            <span
              className="icon"
              style={{
                background: page === "rtl" ? color : "",
              }}
            >
              {rtl}
            </span>
            <span className="label">RTL</span>
          </NavLink>
        </Menu.Item> */}

        {/* <Menu.Item className="menu-item-header" key="5">
          Akun
        </Menu.Item>
        <Menu.Item key="6">
          <NavLink to="/profile">
            <span
              className="icon"
              style={{
                background: page === "profile" ? color : "",
              }}
            >
              {profile}
            </span>
            <span className="label">Profile</span>
          </NavLink>
        </Menu.Item> */}

        {/* <Menu.Item key="7">
          <NavLink to="/sign-in">
            <span className="icon">{signin}</span>
            <span className="label">Sign In</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="8">
          <NavLink to="/sign-up">
            <span className="icon">{signup}</span>
            <span className="label">Sign Up</span>
          </NavLink>
        </Menu.Item> */}
      </Menu>
      <div className="aside-footer">
        <div
          className="footer-box"
          style={{
            background: color,
          }}
        >
          <span className="icon" style={{ color }}>
            {dashboard}
          </span>
          <Title style={{ color: '#fff' }} level={5}>Hi {user == null ? '': user.name}</Title>
          <h6>Selamat Datang</h6>
          <p>di Jaringan Simpul Disperkim</p>
          <Button type="primary" onClick={e => handleLogout(e)} loading={loading} className="ant-btn-sm ant-btn-block">
            LOGOUT
          </Button>
        </div>
      </div>
    </>
  );
}

export default Sidenav;
