import { useEffect, useState } from "react";
import axios from "axios";
import API from "../api/url";
import { PerkimLogin } from "../resource/res";
import { Table, Tag, Card, Typography, Row, Col, Badge } from "antd";
import { FormatRupiah } from "@arismun/format-rupiah";
import { HomeOutlined } from "@ant-design/icons";

const RealisasiKpr = () => {
  const [realisasiKpr, setRealisasiKpr] = useState([]);

  const { Title } = Typography;

  const getRealisasiKpr = async () => {
    await axios({
      method: "get",
      url: `${API.JS_PERKIM}realisasi-kpr-subsidi`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("jstoken")}`,
      },
    })
      .then((response) => {
        const { data } = response.data;
        setRealisasiKpr(data);
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response.status == 401) {
          PerkimLogin();
          getRealisasiKpr();
        }
      });
  };

  useEffect(() => {
    getRealisasiKpr();
  }, []);

  return (
    <>
      <Row className="rowgap-vbox" gutter={[24, 0]}>
        {realisasiKpr.map((item, index) => {
          return (
            <Col
              key={index}
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              className="mb-24"
            >
              <Badge.Ribbon text="Sumber: HREIS" color="cyan">
                <Card bordered={true}>
                  <div className="number">
                    <Row align="middle" gutter={[24, 0]}>
                      <Col xs={24}>
                        <Title level={2} bodyStyle={{ textAlign: "left" }}>
                          {item.tahun}
                        </Title>
                        <Row align="middle" gutter={[24, 0]}>
                          <Col xs={12}>
                            <Card type="inner" title="BP2BT" bordered={true}
                              style={{
                                boxShadow: "0 0 0 0",
                              }}
                              headStyle={{
                                fontWeight: "bold",
                              }}
                            >
                              <Tag
                                style={{
                                  color:
                                    item.bp2bt_nilai_kpr < 0 ? "red" : "#890018",
                                  marginBottom: "5px",
                                }}
                                color="red"
                              >
                                Unit : {item.bp2bt_unit}
                              </Tag>
                              <br />
                              <Tag
                                style={{
                                  color:
                                    item.bp2bt_nilai_kpr < 0 ? "red" : "green",
                                    marginBottom: "5px",
                                }}
                                color="green"
                              >
                                Harga Rumah :{" "}
                                {<FormatRupiah value={item.bp2bt_harga_rumah} />}
                              </Tag>
                              <br />
                              <Tag
                                style={{
                                  color:
                                    item.bp2bt_nilai_kpr < 0 ? "red" : "#4257c5",
                                }}
                                color="geekblue"
                              >
                                Nilai KPR :{" "}
                                {<FormatRupiah value={item.bp2bt_nilai_kpr} />}
                              </Tag>
                            </Card>
                          </Col>
                          <Col xs={12}>
                            <Card type="inner" title="FLPP" bordered={true}
                              style={{
                                boxShadow: "0 0 0 0",
                              }}
                              headStyle={{
                                fontWeight: "bold",
                              }}
                            >
                              <Tag
                                style={{
                                  color:
                                    item.bp2bt_nilai_kpr < 0 ? "red" : "#890018",
                                  marginBottom: "5px",
                                }}
                                color="red"
                              >
                                Unit : {item.flpp_unit}
                              </Tag>
                              <br />
                              <Tag
                                style={{
                                  color:
                                    item.bp2bt_nilai_kpr < 0 ? "red" : "green",
                                  marginBottom: "5px",
                                }}
                                color="green"
                              >
                                Harga Rumah :{" "}
                                {<FormatRupiah value={item.flpp_harga_rumah} />}
                              </Tag>
                              <br />
                              <Tag
                                style={{
                                  color:
                                    item.bp2bt_nilai_kpr < 0 ? "red" : "#4257c5",
                                }}
                                color="geekblue"
                              >
                                Nilai KPR :{" "}
                                {<FormatRupiah value={item.flpp_nilai_kpr} />}
                              </Tag>
                            </Card>
                          </Col>
                        </Row>
                        <br />
                        <Row align="middle" gutter={[24, 0]}>
                          <Col xs={12}>
                            <Card type="inner" title="SSB" bordered={true}
                              style={{
                                boxShadow: "0 0 0 0",
                              }}
                              headStyle={{
                                fontWeight: "bold",
                              }}
                            >
                            <Tag
                                style={{
                                  color:
                                    item.ssb_nilai_kpr < 0 ? "red" : "#890018",
                                  marginBottom: "5px",
                                }}
                                color="red"
                              >
                                Unit : {item.ssb_unit}
                              </Tag>
                              <br />
                              <Tag
                                style={{
                                  color:
                                    item.ssb_nilai_kpr < 0 ? "red" : "green",
                                  marginBottom: "5px",
                                }}
                                color="green"
                              >
                                Harga Rumah :{" "}
                                {<FormatRupiah value={item.ssb_harga_rumah} />}
                              </Tag>
                              <br />
                              <Tag
                                style={{
                                  color:
                                    item.bp2bt_nilai_kpr < 0 ? "red" : "#4257c5",
                                }}
                                color="geekblue"
                              >
                                Nilai KPR :{" "}
                                {<FormatRupiah value={item.ssb_nilai_kpr} />}
                              </Tag>
                            </Card>
                          </Col>
                          <Col xs={12}>
                            <Card type="inner" title="TOTAL" bordered={true}
                              style={{
                                boxShadow: "0 0 0 0",
                              }}
                              headStyle={{
                                fontWeight: "bold",
                              }}
                            >
                            <Tag
                                style={{
                                  color:
                                    item.total_nilai_kpr < 0 ? "red" : "#890018",
                                  marginBottom: "5px",
                                }}
                                color="red"
                              >
                                Unit : {item.total_unit}
                              </Tag>
                              <br />
                              <Tag
                                style={{
                                  color:
                                    item.total_nilai_kpr < 0 ? "red" : "green",
                                  marginBottom: "5px",
                                }}
                                color="green"
                              >
                                Harga Rumah :{" "}
                                {<FormatRupiah value={item.total_harga_rumah} />}
                              </Tag>
                              <br />
                              <Tag
                                style={{
                                  color:
                                    item.bp2bt_nilai_kpr < 0 ? "red" : "#4257c5",
                                }}
                                color="geekblue"
                              >
                                Nilai KPR :{" "}
                                {<FormatRupiah value={item.total_nilai_kpr} />}
                              </Tag>
                            </Card>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </Badge.Ribbon>
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default RealisasiKpr;
