import ReactApexChart from "react-apexcharts";
import eChart from "./configs/eChart";

function EChart({rtlh, backlog}) {
  eChart.options.xaxis.categories = rtlh.map((item) => {
    return item.kokab_name;
  });
  eChart.options.colors = rtlh.map((item) => {
    return '#000';
  });
  eChart.options.yaxis.labels.style.colors = rtlh.map((item) => {
    return '#000';
  });

  const data = rtlh.map((item) => {
    return item.totalrtlh.replace(',', '');
  });

  const data2 = backlog.map((item) => {
    return item.totalbacklog.replace(',', '');
  });

  const series = [
    {
      name: 'Total RTLH',
      data: data,
      color: "#0085ff",
    },
    {
      name: 'Total Backlog',
      data: data2,
      color: '#9400d3'
    },
  ];

  eChart.series = series;

  return (
    <>
      <div id="chart">
        <ReactApexChart
          className=""
          options={eChart.options}
          series={eChart.series}
          type="bar"
          height={550}
        />
      </div>
      {/* <div className="chart-vistior">
        <Title level={5}>Active Users</Title>
        <Paragraph className="lastweek">
          than last week <span className="bnb2">+30%</span>
        </Paragraph>
        <Paragraph className="lastweek">
          We have created multiple options for you to put together and customise
          into pixel perfect pages.
        </Paragraph>
        <Row gutter>
          {items.map((v, index) => (
            <Col xs={6} xl={6} sm={6} md={6} key={index}>
              <div className="chart-visitor-count">
                <Title level={4}>{v.Title}</Title>
                <span>{v.user}</span>
              </div>
            </Col>
          ))}
        </Row>
      </div> */}
    </>
  );
}

export default EChart;
