import { useEffect, useState } from "react";

import {
  Card,
  Col,
  Row,
  Typography,
  message,
  Table,
  Input,
  Space,
  Select,
  Skeleton as Skel,
  InputNumber,
  Empty,
  Button,
  Drawer,
  Divider,
  Tag,
  Carousel,
  Image
} from "antd";
import {
  PieChartOutlined,
  CopyOutlined,
  UserOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import "leaflet/dist/leaflet.css";
import axios from "axios";
import "react-loading-skeleton/dist/skeleton.css";
import API from "../api/url";
import { useHistory } from "react-router-dom";
import './Home.css';
import TotalRenjaChart from "../components/chart/TotalRenjaChart";
import TotalBangubChart from "../components/chart/TotalBangubChart";

const DescriptionItem = ({ title, content }) => (
  <div className="site-description-item-profile-wrapper">
    <p className="site-description-item-profile-p-label">{title}:</p>
    <div className="site-description-item-profile-p-content">{content}</div>
  </div>
);

function Home() {
  const [perum, setPerum] = useState([]);
  const [searchedColumnRenja, setSearchedColumnRenja] = useState("");
  const [searchedColumnBangub, setSearchedColumnBangub] = useState("");
  const [totalDev, setTotalDev] = useState(0);
  const [totalAll, setTotalAll] = useState({
    backlog: 0,
    dev: 0,
    rtlh: 0,
  });
  const [date, setDate] = useState(
    new Date(new Date().setFullYear(new Date().getFullYear() + 1)).getFullYear()
  );
  const [summaryRenja, setSummaryRenja] = useState(null);
  const [summaryRenjaFilter, setSummaryRenjaFilter] = useState(null);
  const [summaryBangub, setSummaryBangub] = useState(null);
  const [summaryBangubFilter, setSummaryBangubFilter] = useState(null);
  const [kabkota, setKabkota] = useState([]);
  const [bidang, setBidang] = useState([]);
  const [dataFilterRenja, setDataFilterRenja] = useState({
    tahun: date,
    kab: "",
    bidang: "",
  });
  const [dataFilterBangub, setDataFilterBangub] = useState({
    tahun: date,
    kab: "",
    bidang: "",
  });
  const [loading, setLoading] = useState(false);
  const [detailRenja, setDetailRenja] = useState(null);
  const [detailBangub, setDetailBangub] = useState(null);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [dateRenja, setDateRenja] = useState(
    new Date(new Date().setFullYear(new Date().getFullYear() + 1)).getFullYear()
  );
  const [dateBangub, setDateBangub] = useState(
    new Date(new Date().setFullYear(new Date().getFullYear() + 1)).getFullYear()
  );
  const [totalRenjaChart, setTotalRenjaChart] = useState([]);
  const [totalBangubChart, setTotalBangubChart] = useState([]);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const showDrawer2 = () => {
    setOpen2(true);
  };
  const onClose2 = () => {
    setOpen2(false);
  };
  const history = useHistory();

  const { Title } = Typography;

  const { Search } = Input;

  const onClickDetail = (id, nomor) => {
    setDetailRenja(null);
    showDrawer();
    getDetailRenja(id, nomor);
  }

  const onClickDetail2 = (id, nomor) => {
    setDetailBangub(null);
    showDrawer2();
    getDetailBangub(id, nomor);
  }

  const getDetailRenja = async (id, nomor) => {
    await axios({
      method: 'post',
      url: `${API.DISPERKIM_UTAMA}data/detail-renja`,
      // data: `id=${v}&nomor_surat=${v}`,
      data: new URLSearchParams({
        id: id,
        nomor_surat: nomor
      }),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    })
    .then((response) => {
      if(response.data != null) {
        const data = response.data.data;
        setDetailRenja(data);
      } else {
        setDetailRenja(null);
      }
    })
    .catch(error => console.log(`Error: ${error}`));
  }

  const getDetailBangub = async (id, nomor) => {
    await axios({
      method: 'post',
      url: `${API.DISPERKIM_UTAMA}data/detail-bangub`,
      // data: `id=${v}&nomor_surat=${v}`,
      data: new URLSearchParams({
        id: id,
        nomor_surat: nomor
      }),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    })
    .then((response) => {
      if(response.data != null) {
        const data = response.data.data;
        setDetailBangub(data);
      } else {
        setDetailBangub(null);
      }
    })
    .catch(error => console.log(`Error: ${error}`));
  }

  /* Fungsi formatRupiah */
  const formatRupiah = (angka, prefix) => {
    let number_string = angka.replace(/[^,\d]/g, '').toString(),
    split   		= number_string.split(','),
    sisa     		= split[0].length % 3,
    rupiah     		= split[0].substr(0, sisa),
    ribuan     		= split[0].substr(sisa).match(/\d{3}/gi);

    // tambahkan titik jika yang di input sudah menjadi angka ribuan
    if(ribuan){
      let separator = sisa ? '.' : '';
      rupiah += separator + ribuan.join('.');
    }

    rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
    return prefix === undefined ? rupiah : (rupiah ? '' + rupiah : '');
  }

  const columnsRenja = [
    {
      title: "DETAIL",
      key: "detail",
      dataIndex: "detail",
      render: (text, record) => (
        <Button style={{
          color: '#fff',
          borderColor: '#d761fd',
          background: '#d761fd',
          textShadow: '0 -1px 0 rgb(0 0 0 / 12%)',
          boxShadow: '0 2px 0 rgb(0 0 0 / 5%)'
        }} key={record.id} size="small" onClick={() => onClickDetail(record.id, record.nomor_surat)}>Detail</Button>
      ),
    },
    {
      title: "NOMOR SURAT",
      dataIndex: "nomor_surat",
      key: "nomor_surat",
    },
    {
      title: "TANGGAL TERBIT",
      dataIndex: "tanggal_terbit_surat",
      key: "tanggal_terbit_surat",
    },
    {
      title: "PROGRAM",
      key: "program",
      dataIndex: "program",
    },
    {
      title: "KEGIATAN",
      key: "kegiatan",
      dataIndex: "kegiatan",
    },
    {
      title: "SUB KEGIATAN",
      key: "sub_kegiatan",
      dataIndex: "sub_kegiatan",
    },
    {
      title: "PROVINSI",
      key: "provinsi",
      dataIndex: "provinsi",
    },
    {
      title: "KAB/KOTA",
      key: "kab_kota",
      dataIndex: "kab_kota",
    },
  ];

  const columnsBangub = [
    {
      title: "DETAIL",
      key: "detail",
      dataIndex: "detail",
      render: (text, record) => (
        <Button style={{
          color: '#fff',
          borderColor: '#d761fd',
          background: '#d761fd',
          textShadow: '0 -1px 0 rgb(0 0 0 / 12%)',
          boxShadow: '0 2px 0 rgb(0 0 0 / 5%)'
        }} key={record.id} size="small" onClick={() => onClickDetail2(record.id, record.nomor_surat)}>Detail</Button>
      ),
    },
    {
      title: "NOMOR SURAT",
      dataIndex: "nomor_surat",
      key: "nomor_surat",
    },
    {
      title: "TANGGAL TERBIT",
      dataIndex: "tanggal_terbit_surat",
      key: "tanggal_terbit_surat",
    },
    {
      title: "PROGRAM",
      key: "program",
      dataIndex: "program",
    },
    {
      title: "KEGIATAN",
      key: "kegiatan",
      dataIndex: "kegiatan",
    },
    {
      title: "SUB KEGIATAN",
      key: "sub_kegiatan",
      dataIndex: "sub_kegiatan",
    },
    {
      title: "PROVINSI",
      key: "provinsi",
      dataIndex: "provinsi",
    },
    {
      title: "KAB/KOTA",
      key: "kab_kota",
      dataIndex: "kab_kota",
    },
  ];

  const contentStyle = {
    // height: '250px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
  };

  const onSearchRenjaHandling = (value) => {
    if (value !== "") {
      if (searchedColumnRenja === "") {
        message.error("Pilih kolom pencarian terlebih dahulu", 1);
      } else {
        const filteredData = summaryRenja.proposals.filter((entry) =>
          entry[searchedColumnRenja].toLowerCase().includes(value.toLowerCase())
        );
        setSummaryRenjaFilter({ ...filteredData, proposals: filteredData });
      }
    } else {
      setSummaryRenjaFilter(summaryRenja);
    }
  };

  const onSearchBangubHandling = (value) => {
    if (value !== "") {
      if (searchedColumnBangub === "") {
        message.error("Pilih kolom pencarian terlebih dahulu", 1);
      } else {
        const filteredData = summaryBangub.proposals.filter((entry) =>
          entry[searchedColumnBangub]
            .toLowerCase()
            .includes(value.toLowerCase())
        );
        setSummaryBangubFilter({ ...filteredData, proposals: filteredData });
      }
    } else {
      setSummaryBangubFilter(summaryBangub);
    }
  };

  // const pencil = [
  //   <svg
  //     width="20"
  //     height="20"
  //     viewBox="0 0 20 20"
  //     fill="none"
  //     xmlns="http://www.w3.org/2000/svg"
  //     key={0}
  //   >
  //     <path
  //       d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z"
  //       className="fill-gray-7"
  //     ></path>
  //     <path
  //       d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z"
  //       className="fill-gray-7"
  //     ></path>
  //   </svg>,
  // ];
  // const deletebtn = [
  //   <svg
  //     width="16"
  //     height="16"
  //     viewBox="0 0 20 20"
  //     fill="none"
  //     xmlns="http://www.w3.org/2000/svg"
  //     key={0}
  //   >
  //     <path
  //       fillRule="evenodd"
  //       clipRule="evenodd"
  //       d="M9 2C8.62123 2 8.27497 2.214 8.10557 2.55279L7.38197 4H4C3.44772 4 3 4.44772 3 5C3 5.55228 3.44772 6 4 6L4 16C4 17.1046 4.89543 18 6 18H14C15.1046 18 16 17.1046 16 16V6C16.5523 6 17 5.55228 17 5C17 4.44772 16.5523 4 16 4H12.618L11.8944 2.55279C11.725 2.214 11.3788 2 11 2H9ZM7 8C7 7.44772 7.44772 7 8 7C8.55228 7 9 7.44772 9 8V14C9 14.5523 8.55228 15 8 15C7.44772 15 7 14.5523 7 14V8ZM12 7C11.4477 7 11 7.44772 11 8V14C11 14.5523 11.4477 15 12 15C12.5523 15 13 14.5523 13 14V8C13 7.44772 12.5523 7 12 7Z"
  //       fill="#111827"
  //       className="fill-danger"
  //     ></path>
  //   </svg>,
  // ];

  const { Option } = Select;

  const handleChangeRenja = (value) => {
    setSearchedColumnRenja(value);
  };

  const handleChangeBangub = (value) => {
    setSearchedColumnBangub(value);
  };

  // const dollor = [
  //   <svg
  //     width="22"
  //     height="22"
  //     viewBox="0 0 20 20"
  //     fill="none"
  //     xmlns="http://www.w3.org/2000/svg"
  //     key={0}
  //   >
  //     <path
  //       d="M8.43338 7.41784C8.58818 7.31464 8.77939 7.2224 9 7.15101L9.00001 8.84899C8.77939 8.7776 8.58818 8.68536 8.43338 8.58216C8.06927 8.33942 8 8.1139 8 8C8 7.8861 8.06927 7.66058 8.43338 7.41784Z"
  //       fill="#fff"
  //     ></path>
  //     <path
  //       d="M11 12.849L11 11.151C11.2206 11.2224 11.4118 11.3146 11.5666 11.4178C11.9308 11.6606 12 11.8861 12 12C12 12.1139 11.9308 12.3394 11.5666 12.5822C11.4118 12.6854 11.2206 12.7776 11 12.849Z"
  //       fill="#fff"
  //     ></path>
  //     <path
  //       fillRule="evenodd"
  //       clipRule="evenodd"
  //       d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM11 5C11 4.44772 10.5523 4 10 4C9.44772 4 9 4.44772 9 5V5.09199C8.3784 5.20873 7.80348 5.43407 7.32398 5.75374C6.6023 6.23485 6 7.00933 6 8C6 8.99067 6.6023 9.76515 7.32398 10.2463C7.80348 10.5659 8.37841 10.7913 9.00001 10.908L9.00002 12.8492C8.60902 12.7223 8.31917 12.5319 8.15667 12.3446C7.79471 11.9275 7.16313 11.8827 6.74599 12.2447C6.32885 12.6067 6.28411 13.2382 6.64607 13.6554C7.20855 14.3036 8.05956 14.7308 9 14.9076L9 15C8.99999 15.5523 9.44769 16 9.99998 16C10.5523 16 11 15.5523 11 15L11 14.908C11.6216 14.7913 12.1965 14.5659 12.676 14.2463C13.3977 13.7651 14 12.9907 14 12C14 11.0093 13.3977 10.2348 12.676 9.75373C12.1965 9.43407 11.6216 9.20873 11 9.09199L11 7.15075C11.391 7.27771 11.6808 7.4681 11.8434 7.65538C12.2053 8.07252 12.8369 8.11726 13.254 7.7553C13.6712 7.39335 13.7159 6.76176 13.354 6.34462C12.7915 5.69637 11.9405 5.26915 11 5.09236V5Z"
  //       fill="#fff"
  //     ></path>
  //   </svg>,
  // ];
  // const profile = [
  //   <svg
  //     width="22"
  //     height="22"
  //     viewBox="0 0 20 20"
  //     fill="none"
  //     xmlns="http://www.w3.org/2000/svg"
  //     key={0}
  //   >
  //     <path
  //       d="M9 6C9 7.65685 7.65685 9 6 9C4.34315 9 3 7.65685 3 6C3 4.34315 4.34315 3 6 3C7.65685 3 9 4.34315 9 6Z"
  //       fill="#fff"
  //     ></path>
  //     <path
  //       d="M17 6C17 7.65685 15.6569 9 14 9C12.3431 9 11 7.65685 11 6C11 4.34315 12.3431 3 14 3C15.6569 3 17 4.34315 17 6Z"
  //       fill="#fff"
  //     ></path>
  //     <path
  //       d="M12.9291 17C12.9758 16.6734 13 16.3395 13 16C13 14.3648 12.4393 12.8606 11.4998 11.6691C12.2352 11.2435 13.0892 11 14 11C16.7614 11 19 13.2386 19 16V17H12.9291Z"
  //       fill="#fff"
  //     ></path>
  //     <path
  //       d="M6 11C8.76142 11 11 13.2386 11 16V17H1V16C1 13.2386 3.23858 11 6 11Z"
  //       fill="#fff"
  //     ></path>
  //   </svg>,
  // ];
  // const heart = [
  //   <svg
  //     width="22"
  //     height="22"
  //     viewBox="0 0 20 20"
  //     fill="none"
  //     xmlns="http://www.w3.org/2000/svg"
  //     key={0}
  //   >
  //     <path
  //       fillRule="evenodd"
  //       clipRule="evenodd"
  //       d="M3.17157 5.17157C4.73367 3.60948 7.26633 3.60948 8.82843 5.17157L10 6.34315L11.1716 5.17157C12.7337 3.60948 15.2663 3.60948 16.8284 5.17157C18.3905 6.73367 18.3905 9.26633 16.8284 10.8284L10 17.6569L3.17157 10.8284C1.60948 9.26633 1.60948 6.73367 3.17157 5.17157Z"
  //       fill="#fff"
  //     ></path>
  //   </svg>,
  // ];
  // const cart = [
  //   <svg
  //     width="22"
  //     height="22"
  //     viewBox="0 0 20 20"
  //     fill="none"
  //     xmlns="http://www.w3.org/2000/svg"
  //     key={0}
  //   >
  //     <path
  //       fillRule="evenodd"
  //       clipRule="evenodd"
  //       d="M10 2C7.79086 2 6 3.79086 6 6V7H5C4.49046 7 4.06239 7.38314 4.00612 7.88957L3.00612 16.8896C2.97471 17.1723 3.06518 17.455 3.25488 17.6669C3.44458 17.8789 3.71556 18 4 18H16C16.2844 18 16.5554 17.8789 16.7451 17.6669C16.9348 17.455 17.0253 17.1723 16.9939 16.8896L15.9939 7.88957C15.9376 7.38314 15.5096 7 15 7H14V6C14 3.79086 12.2091 2 10 2ZM12 7V6C12 4.89543 11.1046 4 10 4C8.89543 4 8 4.89543 8 6V7H12ZM6 10C6 9.44772 6.44772 9 7 9C7.55228 9 8 9.44772 8 10C8 10.5523 7.55228 11 7 11C6.44772 11 6 10.5523 6 10ZM13 9C12.4477 9 12 9.44772 12 10C12 10.5523 12.4477 11 13 11C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9Z"
  //       fill="#fff"
  //     ></path>
  //   </svg>,
  // ];
  const token = localStorage.getItem("token");

  const me = async () => {
    await axios
      .get(`${API.JS_PERKIM}me`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.statusText === "OK") {
          localStorage.setItem("user", JSON.stringify(response.data.data));
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log("Error Main", error.response.data.message);
          message.error(error.response.data.message, 5);
          if (error.response.data.message === "Unauthenticated.") {
            localStorage.setItem("token", "");
            localStorage.setItem("isLogin", false);
            localStorage.setItem("user", "");
            history.replace("/sign-in");
          }
        } else if (error.request) {
          message.error("Network Error");
          localStorage.setItem("token", "");
          localStorage.setItem("isLogin", false);
          localStorage.setItem("user", "");
          history.replace("/sign-in");
        } else {
          message.error(error.message);
          localStorage.setItem("token", "");
          localStorage.setItem("isLogin", false);
          localStorage.setItem("user", "");
          history.replace("/sign-in");
        }
      });
  };

  const getDataRenja = async () => {
    setLoading(true);
    await axios({
      method: "post",
      url: `${API.DISPERKIM_UTAMA}data/summary-renja`,
      data: dataFilterRenja,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        setLoading(false);
        const data = response.data.data;
        setSummaryRenja(data);
        setSummaryRenjaFilter(data);
      })
      .catch((error) => {
        setSummaryRenja(error.response.data.data);
        setSummaryRenjaFilter(error.response.data.data);
        setLoading(false);
        console.log(`Error: ${error}`);
      });
  };

  const getDataBangub = async () => {
    setLoading(true);
    await axios({
      method: "post",
      url: `${API.DISPERKIM_UTAMA}data/summary-bangub`,
      data: dataFilterBangub,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        setLoading(false);
        const data = response.data.data;
        setSummaryBangub(data);
        setSummaryBangubFilter(data);
      })
      .catch((error) => {
        setSummaryBangub(error.response.data.data);
        setSummaryBangubFilter(error.response.data.data);
        setLoading(false);
        console.log(`Error: ${error}`);
      });
  };

  const columnsDetailRenja = [
    {
      title: 'Data',
      dataIndex: 'data',
      key: 'data',
    },
    {
      title: 'Nama',
      dataIndex: 'nama',
      key: 'nama',
    },
    {
      title: 'Kode',
      dataIndex: 'kode',
      key: 'kode',
    },
    {
      title: 'Kinerja',
      dataIndex: 'kinerja',
      key: 'kinerja',
    },
    {
      title: 'Indikator',
      dataIndex: 'indikator',
      key: 'indikator',
    },
  ];

  useEffect(() => {
    me();
  }, []);

  useEffect(() => {
    axios.post(`${API.RUMAH_WONG_KITO}public_perumlist/ajax_data`)
    .then((response) => {
      const data = response.data.data;
      setPerum(data);
    })
    .catch(error => console.log(`Error: ${error}`));

    axios
      .post(`${API.RUMAH_WONG_KITO}public_dev/total_dev`)
      .then((response) => {
        const data = response.data.data.total;
        setTotalDev(data);
      })
      .catch((error) => console.log(`Error: ${error}`));

    axios
      .post(`${API.RUMAH_WONG_KITO}public_home/data_all`)
      .then((response) => {
        const data = response.data[0];
        setTotalAll(data);
      })
      .catch((error) => console.log(`Error: ${error}`));

    axios({
      method: "post",
      url: `${API.DISPERKIM_UTAMA}data/summary-bangub`,
      data: {
        tahun: date,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        const data = response.data.data;
        setSummaryBangub(data);
        setSummaryBangubFilter(data);
      })
      .catch((error) => console.log(`Error: ${error}`));

    axios({
      method: "get",
      url: `${API.DISPERKIM_UTAMA}master/wilayah-kab-kota/16`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        const data = response.data.results;
        setKabkota(data);
      })
      .catch((error) => console.log(`Error: ${error}`));

    axios({
      method: "get",
      url: `${API.DISPERKIM_UTAMA}master/data-bidang`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        const data = response.data.results;
        setBidang(data);
      })
      .catch((error) => console.log(`Error: ${error}`));

    axios({
      method: "post",
      url: `${API.DISPERKIM_UTAMA}data/total-renja`,
      data: new URLSearchParams({
        tahun_anggaran: dateRenja,
      }),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    })
    .then((response) => {
      const data = response.data.data;
      setTotalRenjaChart(data);
    })
    .catch((error) => console.log(`Error: ${error}`));

    axios({
      method: "post",
      url: `${API.DISPERKIM_UTAMA}data/total-bangub`,
      data: new URLSearchParams({
        tahun_anggaran: dateBangub,
      }),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    })
    .then((response) => {
      const data = response.data.data;
      setTotalBangubChart(data);
    })
    .catch((error) => console.log(`Error: ${error}`));
  }, []);

  useEffect(() => {
    getDataRenja();
    getDataBangub();
  }, [dataFilterRenja, dataFilterBangub]);

  // useEffect(() => {
  //   getDataBangub();
  // }, [dataFilterBangub]);

  return (
    <>
      <div className="layout-content">
        <Row className="rowgap-vbox" gutter={[24, 0]}>
          <Col
            // key={index}
            xs={24}
            sm={24}
            md={12}
            lg={6}
            xl={6}
            className="mb-24"
          >
            <Card bordered={false} className="criclebox ">
              <div className="number">
                <Row align="middle" gutter={[24, 0]}>
                  <Col xs={18}>
                    <span>Total Perumahan</span>
                    <Title level={3}>{perum.length}</Title>
                    <small>Sumber: </small>
                    <small className="bnb2">Rumah Wong Kito</small>
                  </Col>
                  <Col xs={6}>
                    <div className="icon-box">
                      <HomeOutlined />
                    </div>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
          <Col
            // key={index}
            xs={24}
            sm={24}
            md={12}
            lg={6}
            xl={6}
            className="mb-24"
          >
            <Card bordered={false} className="criclebox ">
              <div className="number">
                <Row align="middle" gutter={[24, 0]}>
                  <Col xs={18}>
                    <span>Total Developer</span>
                    <Title level={3}>{totalDev}</Title>
                    <small>Sumber: </small>
                    <small className="bnb2">Rumah Wong Kito</small>
                  </Col>
                  <Col xs={6}>
                    <div className="icon-box">
                      <UserOutlined />
                    </div>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
          <Col
            // key={index}
            xs={24}
            sm={24}
            md={12}
            lg={6}
            xl={6}
            className="mb-24"
          >
            <Card bordered={false} className="criclebox ">
              <div className="number">
                <Row align="middle" gutter={[24, 0]}>
                  <Col xs={18}>
                    <span>Total Backlog</span>
                    <Title level={3}>{totalAll.backlog}</Title>
                    <small>Sumber: </small>
                    <small className="bnb2">Rumah Wong Kito</small>
                  </Col>
                  <Col xs={6}>
                    <div className="icon-box">
                      <CopyOutlined />
                    </div>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
          <Col
            // key={index}
            xs={24}
            sm={24}
            md={12}
            lg={6}
            xl={6}
            className="mb-24"
          >
            <Card bordered={false} className="criclebox ">
              <div className="number">
                <Row align="middle" gutter={[24, 0]}>
                  <Col xs={18}>
                    <span>Total RTLH</span>
                    <Title level={3}>{totalAll.rtlh}</Title>
                    <small>Sumber: </small>
                    <small className="bnb2">Rumah Wong Kito</small>
                  </Col>
                  <Col xs={6}>
                    <div className="icon-box">
                      <PieChartOutlined />
                    </div>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
        <Col
            // key={index}
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            className="mb-24"
          >
            <Card bordered={false} className="criclebox h-full">
              <div className="number">
                <Row align="middle" gutter={[24, 0]}>
                  <Col xs={18}>
                    <span>Total BANGUB</span>
                    <Title level={3}>
                      {summaryBangub == null ? (
                        <Skel.Button size="default" shape="circle" />
                      ) : (
                        summaryBangub.total
                      )}
                    </Title>
                    <small>Sumber: </small>
                    <small className="bnb3">Disperkim</small>
                  </Col>
                  <Col xs={6}>
                    <div className="icon-box">
                      <PieChartOutlined />
                    </div>
                  </Col>
                </Row>
              </div>
              <Card bordered={false} className="criclebox cardbody h-full">
                <div className="project-ant">
                  <div className="ant-filtertabs">
                    <div className="antd-pro-pages-dashboard-analysis-style-salesExtra">
                      {/* <Radio.Group onChange={onChange} defaultValue="a">
                        <Radio.Button value="a">ALL</Radio.Button>
                        <Radio.Button value="b">ONLINE</Radio.Button>
                        <Radio.Button value="c">STORES</Radio.Button>
                      </Radio.Group> */}
                      <Space>
                        <Space direction="vertical">
                          <Select
                            bordered={true}
                            size="large"
                            style={{
                              width: 150,
                            }}
                            placeholder="Pilih Kolom"
                            onChange={handleChangeBangub}
                          >
                            {columnsBangub.map((c, index) => (
                              <Option key={c.key} value={c.key}>
                                {c.title}
                              </Option>
                            ))}
                          </Select>
                        </Space>

                        <Search
                          style={{ width: 200 }}
                          placeholder="Search"
                          onChange={(e) =>
                            onSearchBangubHandling(e.target.value)
                          }
                          enterButton
                        />
                      </Space>
                    </div>
                  </div>
                </div>
                <br/>
                <div className="project-ant">
                  <div className="ant-filtertabs">
                    <Select
                      bordered={true}
                      size="large"
                      style={{
                        width: 150,
                      }}
                      allowClear
                      placeholder="Filter Kab/Kota"
                      onChange={async (e) => {
                        if (e) {
                          setDataFilterBangub({ ...dataFilterBangub, ...{kab: e }});
                        } else {
                          setDataFilterBangub({ ...dataFilterBangub, ...{kab: ""} });
                        }
                      }}
                    >
                      {kabkota.map((c, index) => (
                        <Option key={c.id} value={c.id}>
                          {c.text}
                        </Option>
                      ))}
                    </Select>
                    <Select
                      bordered={true}
                      size="large"
                      style={{
                        width: 150,
                      }}
                      allowClear
                      placeholder="Filter Bidang"
                      onChange={async (e) => {
                        if (e) {
                          setDataFilterBangub({ ...dataFilterBangub, ...{bidang: e } });
                        } else {
                          setDataFilterBangub({ ...dataFilterBangub, ...{bidang: ""} });
                        }
                      }}
                    >
                      {bidang.map((c, index) => (
                        <Option key={c.id} value={c.id}>
                          {c.text}
                        </Option>
                      ))}
                    </Select>
                    <InputNumber
                      style={{ width: 150 }}
                      size="large"
                      name="test"
                      onChange={async (e) => {
                        setDataFilterBangub(dataFilterBangub => ({ 
                          ...dataFilterBangub, 
                          tahun: e
                        }));
                      }}
                      placeholder="Filter Tahun"
                      min={1970}
                      max={9999}
                      defaultValue={date}
                    />
                  </div>
                </div>
                <div className="ant-list-box table-responsive">
                  <div style={{ padding: "20px" }}>
                    {summaryBangubFilter != null ? (
                      <Table
                        key={"tableBangub"}
                        bordered={true}
                        columns={columnsBangub}
                        dataSource={summaryBangubFilter.proposals}
                        size="small"
                        pagination={true}
                        rowKey="id"
                        className="ant-border-space"
                      />
                    ) : <Empty />}
                  </div>
                </div>
              </Card>
            </Card>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col
            // key={index}
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            className="mb-24"
          >
            <Card bordered={false} className="criclebox h-full">
              <div className="number">
                <Row align="middle" gutter={[24, 0]}>
                  <Col xs={18}>
                    <span>Total RENJA</span>
                    <Title level={3}>
                      {summaryRenja == null ? (
                        <Skel.Button size="default" shape="circle" />
                      ) : (
                        summaryRenja.total
                      )}
                    </Title>
                    <small>Sumber: </small>
                    <small className="bnb3">Disperkim</small>
                  </Col>
                  <Col xs={6}>
                    <div className="icon-box">
                      <PieChartOutlined />
                    </div>
                  </Col>
                </Row>
              </div>
              <Card bordered={false} className="criclebox cardbody h-full">
                <div className="project-ant">
                  <div className="ant-filtertabs">
                    <div className="antd-pro-pages-dashboard-analysis-style-salesExtra">
                      {/* <Radio.Group onChange={onChange} defaultValue="a">
                        <Radio.Button value="a">ALL</Radio.Button>
                        <Radio.Button value="b">ONLINE</Radio.Button>
                        <Radio.Button value="c">STORES</Radio.Button>
                      </Radio.Group> */}
                      <Space>
                        <Space direction="vertical">
                          <Select
                            bordered={true}
                            size="large"
                            style={{
                              width: 150,
                            }}
                            placeholder="Pilih Kolom"
                            onChange={handleChangeRenja}
                          >
                            {columnsRenja.map((c, index) => (
                              <Option key={c.key} value={c.key}>
                                {c.title}
                              </Option>
                            ))}
                          </Select>
                        </Space>

                        <Search
                          style={{ width: 200 }}
                          placeholder="Search"
                          onChange={(e) =>
                            onSearchRenjaHandling(e.target.value)
                          }
                          enterButton
                        />
                      </Space>
                    </div>
                  </div>
                </div>
                <br />
                <div className="project-ant">
                  <div className="ant-filtertabs">
                    <Select
                      bordered={true}
                      size="large"
                      style={{
                        width: 150,
                      }}
                      allowClear
                      placeholder="Filter Kab/Kota"
                      onChange={async (e) => {
                        if (e) {
                          setDataFilterRenja({ ...dataFilterRenja, ...{kab: e }});
                        } else {
                          setDataFilterRenja({ ...dataFilterRenja, ...{kab: ""} });
                        }
                      }}
                    >
                      {kabkota.map((c, index) => (
                        <Option key={c.id} value={c.id}>
                          {c.text}
                        </Option>
                      ))}
                    </Select>
                    <Select
                      bordered={true}
                      size="large"
                      style={{
                        width: 150,
                      }}
                      allowClear
                      placeholder="Filter Bidang"
                      onChange={async (e) => {
                        if (e) {
                          setDataFilterRenja({ ...dataFilterRenja, ...{bidang: e } });
                        } else {
                          setDataFilterRenja({ ...dataFilterRenja, ...{bidang: ""} });
                        }
                      }}
                    >
                      {bidang.map((c, index) => (
                        <Option key={c.id} value={c.id}>
                          {c.text}
                        </Option>
                      ))}
                    </Select>
                    <InputNumber
                      style={{ width: 150 }}
                      size="large"
                      name="test"
                      onChange={async (e) => {
                        setDataFilterRenja(dataFilterRenja => ({ 
                          ...dataFilterRenja, 
                          tahun: e
                        }));
                      }}
                      placeholder="Filter Tahun"
                      min={1970}
                      max={9999}
                      defaultValue={date}
                    />
                  </div>
                </div>
                <div className="ant-list-box table-responsive">
                  <div style={{ padding: "20px" }}>
                    {summaryRenjaFilter != null ? (
                      <Table
                        id={"tableRenja"}
                        bordered={true}
                        columns={columnsRenja}
                        dataSource={summaryRenjaFilter.proposals}
                        size="small"
                        pagination={true}
                        rowKey="id"
                        className="ant-border-space"
                      />
                    ) : <Empty />}
                  </div>
                </div>
              </Card>
            </Card>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <h2>Grafik Total Bangub</h2>
              <TotalBangubChart total={totalBangubChart}/>
            </Card>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <h2>Grafik Total Renja</h2>
              <TotalRenjaChart total={totalRenjaChart}/>
            </Card>
          </Col>
        </Row>
      </div>
      <Drawer width={640} placement="right" closable={true} onClose={onClose2} visible={open2}>
        {detailBangub != null ? (
          <>
            <h2
              className="site-description-item-profile-p"
              style={{
                marginBottom: 24,
              }}
            >
              Detail Renja
            </h2>
            <h3 className="site-description-item-profile-p">Lokasi</h3>
            <Row>
              <Col span={24}>
                <DescriptionItem title="Nama" content={detailBangub.lokasi_teks} />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <DescriptionItem title="Long-Lat" content={
                  detailBangub.lokasi_longlat.length > 0 ? (
                  <>
                    {detailBangub.lokasi_longlat.map((c, index) => (<>
                      <span key={index}>{c.latitude}, {c.longitude}</span> |
                      </>
                    ))}
                  </>
                  ) : (
                    "-"
                  )
                } />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <DescriptionItem title="Provinsi" content={detailBangub.provinsi} />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <DescriptionItem title="Kab/Kota" content={detailBangub.kab_kota} />
              </Col>
            </Row>
            <Divider />
            <h3 className="site-description-item-profile-p">Proposal</h3>
            <Row>
              <Col span={24}>
                <DescriptionItem title="Jenis Pengajuan" content={
                  <Tag color="#108ee9">{detailBangub.jenis_pengajuan}</Tag>
                } />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <DescriptionItem title="Judul" content={detailBangub.judul_proposal_kegiatan} />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <DescriptionItem title="Waktu Pengajuan" content={detailBangub.waktu_pengajuan} />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <DescriptionItem title="Tanggal Terbit" content={detailBangub.tanggal_terbit_surat} />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <DescriptionItem title="Tahun Anggaran" content={<Tag color="geekblue">{detailBangub.tahun_anggaran}</Tag>} />
              </Col>
              <Col span={12}>
                <DescriptionItem title="Tahun Pengajuan" content={<Tag color="cyan">{detailBangub.tahun_pengajuan}</Tag>} />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <DescriptionItem title="Sasaran" content={<Tag color="red">{detailBangub.sasaran_volume} {detailBangub.sasaran_satuan}</Tag>} />
              </Col>
            </Row>
            <Divider />
            <h3 className="site-description-item-profile-p">Program</h3>
            <Row>
              <Col span={24}>
                <DescriptionItem title="Nomor Surat" content={detailBangub.nomor_surat} />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Table 
                  columns={[
                    {
                      title: 'DATA',
                      dataIndex: 'data',
                      key: 'data',
                    },
                    {
                      title: 'NAMA',
                      dataIndex: 'nama',
                      key: 'nama',
                    },
                    {
                      title: 'KODE',
                      dataIndex: 'kode',
                      key: 'kode',
                    },
                    {
                      title: 'KINERJA',
                      dataIndex: 'kinerja',
                      key: 'kinerja',
                    },
                    {
                      title: 'INDIKATOR',
                      dataIndex: 'indikator',
                      key: 'indikator',
                    },
                  ]} 
                  dataSource={[
                    (detailBangub.program != null) ?
                    {
                      key: 'program_data',
                      data: 'Program',
                      nama: <>{detailBangub.program.nama}</>,
                      kode: <>{detailBangub.program.kode}</>,
                      kinerja: <>{detailBangub.program.kinerja}</>,
                      indikator: <>{detailBangub.program.indikator}</>,
                    } : null,
                    (detailBangub.kegiatan != null) ?
                    {
                      key: 'kegiatan_data',
                      data: 'Kegiatan',
                      nama: <>{detailBangub.kegiatan.nama}</>,
                      kode: <>{detailBangub.kegiatan.kode}</>,
                      kinerja: <>{detailBangub.kegiatan.kinerja}</>,
                      indikator: <>{detailBangub.kegiatan.indikator}</>,
                    } : null,
                    (detailBangub.sub_kegiatan != null) ?
                    {
                      key: 'sub_kegiatan_data',
                      data: 'Sub Kegiatan',
                      nama: <>{detailBangub.sub_kegiatan.nama}</>,
                      kode: <>{detailBangub.sub_kegiatan.kode}</>,
                      kinerja: <>{detailBangub.sub_kegiatan.kinerja}</>,
                      indikator: <>{detailBangub.sub_kegiatan.indikator}</>,
                    } : null,
                  ]}
                  scroll={{
                    x: true,
                  }}
                  pagination={false}
                  bordered
                />
              </Col>
            </Row>
            <br/>
            <Row>
              <Col span={24}>
                <DescriptionItem
                  title="Pagu"
                  content={detailBangub.pagu ? formatRupiah(detailBangub.pagu.toString(), '') : "-"}
                />
                <DescriptionItem
                  title="APBD Kab/Kota"
                  content={detailBangub.apbd_kab_kota ? formatRupiah(detailBangub.apbd_kab_kota.toString(), '') : "-"}
                />
                <DescriptionItem
                  title="APBD Provinsi"
                  content={detailBangub.apbd_prov ? formatRupiah(detailBangub.apbd_prov.toString(), '') : "-"}
                />
                <DescriptionItem
                  title="APBN PHLN"
                  content={detailBangub.apbn_phln ? formatRupiah(detailBangub.apbn_phln.toString(), '') : "-"}
                />
                <DescriptionItem
                  title="APBN RPM"
                  content={detailBangub.apbn_rpm ? formatRupiah(detailBangub.apbn_rpm.toString(), '') : "-"}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <DescriptionItem
                  title="File Surat"
                  content={
                    <a href={API.DISPERKIM_BASE_URL+detailBangub.file_surat} target="_blank" rel="noopener noreferrer">Download</a>
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <p className="site-description-item-profile-p-label">Foto Kondisi Awal</p>
                <Carousel autoplay>
                  {detailBangub.semua_foto_kondisi_awal.length > 0 ? (
                      detailBangub.semua_foto_kondisi_awal.map((c, index) => (
                        <div key={index}>
                          {/* <h3 style={contentStyle}>1</h3> */}
                          <Image
                            style={contentStyle}
                            width="100%"
                            src={API.DISPERKIM_BASE_URL+c.foto}
                          />
                        </div>
                      ))
                      ) : (
                        <div>
                      <Empty />
                    </div>
                  )}
                </Carousel>
              </Col>
            </Row>
          </>
        ): (
          <Skel active />
        )}
      </Drawer>
      <Drawer width={640} placement="right" closable={true} onClose={onClose} visible={open}>
        {detailRenja != null ? (
          <>
            <h2
              className="site-description-item-profile-p"
              style={{
                marginBottom: 24,
              }}
            >
              Detail Renja
            </h2>
            <h3 className="site-description-item-profile-p">Lokasi</h3>
            <Row>
              <Col span={24}>
                <DescriptionItem title="Nama" content={detailRenja.lokasi_teks} />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <DescriptionItem title="Long-Lat" content={
                  detailRenja.lokasi_longlat.length > 0 ? (
                  <>
                    {detailRenja.lokasi_longlat.map((c, index) => (
                      <div key={index}>{c.longitude}, {c.latitude}</div>
                    ))}
                  </>
                  ) : (
                    "-"
                  )
                } />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <DescriptionItem title="Provinsi" content={detailRenja.provinsi} />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <DescriptionItem title="Kab/Kota" content={detailRenja.kab_kota} />
              </Col>
            </Row>
            <Divider />
            <h3 className="site-description-item-profile-p">Proposal</h3>
            <Row>
              <Col span={24}>
                <DescriptionItem title="Jenis Pengajuan" content={
                  <Tag color="#108ee9">{detailRenja.jenis_pengajuan}</Tag>
                } />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <DescriptionItem title="Judul" content={detailRenja.judul_proposal_kegiatan} />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <DescriptionItem title="Waktu Pengajuan" content={detailRenja.waktu_pengajuan} />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <DescriptionItem title="Tanggal Terbit" content={detailRenja.tanggal_terbit_surat} />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <DescriptionItem title="Tahun Anggaran" content={<Tag color="geekblue">{detailRenja.tahun_anggaran}</Tag>} />
              </Col>
              <Col span={12}>
                <DescriptionItem title="Tahun Pengajuan" content={<Tag color="cyan">{detailRenja.tahun_pengajuan}</Tag>} />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <DescriptionItem title="Sasaran" content={<Tag color="red">{detailRenja.sasaran_volume} {detailRenja.sasaran_satuan}</Tag>} />
              </Col>
            </Row>
            <Divider />
            <h3 className="site-description-item-profile-p">Program</h3>
            <Row>
              <Col span={24}>
                <DescriptionItem title="Nomor Surat" content={detailRenja.nomor_surat} />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Table 
                  columns={[
                    {
                      title: 'DATA',
                      dataIndex: 'data',
                      key: 'data',
                    },
                    {
                      title: 'NAMA',
                      dataIndex: 'nama',
                      key: 'nama',
                    },
                    {
                      title: 'KODE',
                      dataIndex: 'kode',
                      key: 'kode',
                    },
                    {
                      title: 'KINERJA',
                      dataIndex: 'kinerja',
                      key: 'kinerja',
                    },
                    {
                      title: 'INDIKATOR',
                      dataIndex: 'indikator',
                      key: 'indikator',
                    },
                  ]} 
                  dataSource={[
                    (detailRenja.program != null) ?
                    {
                      key: 'program_data',
                      data: 'Program',
                      nama: <>{detailRenja.program.nama}</>,
                      kode: <>{detailRenja.program.kode}</>,
                      kinerja: <>{detailRenja.program.kinerja}</>,
                      indikator: <>{detailRenja.program.indikator}</>,
                    } : null,
                    (detailRenja.kegiatan != null) ?
                    {
                      key: 'kegiatan_data',
                      data: 'Kegiatan',
                      nama: <>{detailRenja.kegiatan.nama}</>,
                      kode: <>{detailRenja.kegiatan.kode}</>,
                      kinerja: <>{detailRenja.kegiatan.kinerja}</>,
                      indikator: <>{detailRenja.kegiatan.indikator}</>,
                    } : null,
                    (detailRenja.sub_kegiatan != null) ?
                    {
                      key: 'sub_kegiatan_data',
                      data: 'Sub Kegiatan',
                      nama: <>{detailRenja.sub_kegiatan.nama}</>,
                      kode: <>{detailRenja.sub_kegiatan.kode}</>,
                      kinerja: <>{detailRenja.sub_kegiatan.kinerja}</>,
                      indikator: <>{detailRenja.sub_kegiatan.indikator}</>,
                    } : null,
                  ]}
                  scroll={{
                    x: true,
                  }}
                  pagination={false}
                  bordered
                />
              </Col>
            </Row>
            <br/>
            {/* <Row>
              <Col span={24}>
                <DescriptionItem
                  title="Pagu"
                  content={detailRenja.pagu ? formatRupiah(detailRenja.pagu.toString(), '') : "-"}
                />
                <DescriptionItem
                  title="APBD Kab/Kota"
                  content={detailRenja.apbd_kab_kota ? formatRupiah(detailRenja.apbd_kab_kota.toString(), '') : "-"}
                />
                <DescriptionItem
                  title="APBD Provinsi"
                  content={detailRenja.apbd_prov ? formatRupiah(detailRenja.apbd_prov.toString(), '') : "-"}
                />
                <DescriptionItem
                  title="APBN PHLN"
                  content={detailRenja.apbn_phln ? formatRupiah(detailRenja.apbn_phln.toString(), '') : "-"}
                />
                <DescriptionItem
                  title="APBN RPM"
                  content={detailRenja.apbn_rpm ? formatRupiah(detailRenja.apbn_rpm.toString(), '') : "-"}
                />
              </Col>
            </Row> */}
            <Row>
              <Col span={24}>
                <DescriptionItem
                  title="File Surat"
                  content={
                    <a href={API.DISPERKIM_BASE_URL+detailRenja.file_surat} target="_blank" rel="noopener noreferrer">Download</a>
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <p className="site-description-item-profile-p-label">Foto Kondisi Awal</p>
                <Carousel autoplay>
                  {detailRenja.semua_foto_kondisi_awal.length > 0 ? (
                      detailRenja.semua_foto_kondisi_awal.map((c, index) => (
                        <div key={index}>
                          {/* <h3 style={contentStyle}>1</h3> */}
                          <Image
                            style={contentStyle}
                            width="100%"
                            src={API.DISPERKIM_BASE_URL+c.foto}
                          />
                        </div>
                      ))
                      ) : (
                        <div>
                      <Empty />
                    </div>
                  )}
                </Carousel>
              </Col>
            </Row>
          </>
        ): (
          <Skel active />
        )}
      </Drawer>
    </>
  );
}

export default Home;
