import { useState, useEffect } from "react";
import TotalDashboard from "./DashboardTotal";
import { Card, Col, Row, Typography, Space, Input, Table } from "antd";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import axios from "axios";
import API from "../api/url";
import FlppChart from "../components/chart/FlppChart";
import Paragraph from "antd/lib/typography/Paragraph";

const Flpp = () => {
  const [flpp, setFlpp] = useState([]);
  const [flppFilter, setFlppFilter] = useState([]);
  const [loading, setLoading] = useState(false);
  const { Title } = Typography;
  const { Search } = Input;

  const onSearchFlppHandling = value => {
    setLoading(true);
    if(value !== ''){
      const filteredData = flpp.filter(entry => entry.flpp_bank.toLowerCase().includes(value.toLowerCase()));
      setFlppFilter(filteredData);
      setLoading(false);
    }else{
      setFlppFilter(flpp);
      setLoading(false);
    }
  };
  
  useEffect(() => {
    axios.post(`${API.RUMAH_WONG_KITO}public_dev/get_flpp`)
    .then((response) => {
      const data = response.data.data.flpp;
      setFlpp(data);
      setFlppFilter(data);
    })
    .catch(error => console.log(`Error: ${error}`));
  }, []);
  return (
    <>
      <TotalDashboard />
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} className="mb-24">
          <Card bordered={false} className="criclebox h-full">
            {flpp.length > 0 ? (
              <FlppChart flpp={flpp} />
            ) : (
              <SkeletonTheme height={400}>
                <p>
                  <Skeleton />
                </p>
              </SkeletonTheme>
            )}
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} className="mb-24">
          <Card bordered={false} className="criclebox h-full">
            <div className="project-ant">
              <div>
                <Title level={5}>Table Flpp</Title>
                <Paragraph className="lastweek">
                  Sumber Data : <span className="blue">Rumah Wong Kito</span>
                </Paragraph>
              </div>
              <div className="ant-filtertabs">
                <div className="antd-pro-pages-dashboard-analysis-style-salesExtra">
                  <Space>
                    <Search
                      style={{ width: 170 }}
                      placeholder="Search"
                      onChange={(e) => onSearchFlppHandling(e.target.value)}
                      enterButton
                    />
                  </Space>
                </div>
              </div>
            </div>
            <div className="ant-list-box table-responsive">
              <div style={{ padding: "20px" }}>
                {flpp.length > 0 ? (
                  <Table
                    bordered={true}
                    columns={[
                      {
                        title: "FLPP BANK",
                        dataIndex: "flpp_bank",
                        key: "flpp_bank",
                      },
                      {
                        title: "UNIT",
                        dataIndex: "flpp_unit",
                        key: "flpp_unit",
                      },
                    ]}
                    dataSource={flppFilter}
                    size="small"
                    loading={loading}
                    pagination={{
                      pageSize: 5,
                    }}
                    rowKey="flpp_id"
                    className="ant-border-space"
                  />
                ) : (
                  <SkeletonTheme height={550}>
                    <p>
                      <Skeleton />
                    </p>
                  </SkeletonTheme>
                )}
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Flpp;
