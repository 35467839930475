import { Layout, Row, Col } from "antd";
import { SafetyOutlined } from "@ant-design/icons";

function Footer() {
  const { Footer: AntFooter } = Layout;

  return (
    <AntFooter style={{ background: "#fafafa" }}>
      <Row className="just">
        <Col xs={24} md={12} lg={12}>
          <div className="copyright">
            © {new Date().getFullYear()} di kembangkan 
            {<SafetyOutlined />} oleh
            <a href="#" className="font-weight-bold">
              Tim IT
            </a>
          </div>
        </Col>
        <Col xs={24} md={12} lg={12}>
          <div className="footer-menu">
            <ul>
              <li className="nav-item">
                <a
                  href="#"
                  className="nav-link text-muted"
                >
                  Tentang Kami
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#"
                  className="nav-link text-muted"
                >
                  Visi Misi
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#"
                  className="nav-link pe-0 text-muted"
                >
                  Panduan Teknis
                </a>
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </AntFooter>
  );
}

export default Footer;
