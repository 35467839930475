import { useEffect, useState } from "react";
import { Card, Col, Row, Typography, Table, Alert, Select, Input, Carousel, Badge, Empty } from "antd";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import axios from "axios";
import API from "../api/url";

const HousingPage = () => {
    const { Title, Text } = Typography;
    const { Search } = Input;
    const { Option } = Select;

    const [dataPengembang, setDataPengembang] = useState([]);
    const [dataMasyarakat, setDataMasyarakat] = useState([]);
    const [dataLokasi, setDataLokasi] = useState([]);

    const contentStyle = {
        height: '200px',
        color: '#fff',
        lineHeight: '200px',
        textAlign: 'center',
        background: '#364d79',
    };

    const columns = [
        {
            'title': 'Nama',
            'dataIndex': 'nama',
            'key': 'nama',
            'responsive': ['md'],
        },
        {
            'title': 'Alamat',
            'dataIndex': 'alamat',
            'key': 'alamat',
            'responsive': ['md'],
        },
        // {
        //     'title': 'Latitude',
        //     'dataIndex': 'latitude',
        //     'key': 'latitude',
        //     'responsive': ['md'],
        // },
        // {
        //     'title': 'Longitude',
        //     'dataIndex': 'longitude',
        //     'key': 'longitude',
        //     'responsive': ['md'],
        // },
        // {
        //     'title': 'Provinsi',
        //     'dataIndex': 'provinsi',
        //     'key': 'provinsi',
        //     'responsive': ['md'],
        // },
        {
            'title': 'Kabupaten',
            'dataIndex': 'kabupaten',
            'key': 'kabupaten',
            'responsive': ['md'],
        },
        {
            'title': 'Kecamatan',
            'dataIndex': 'kecamatan',
            'key': 'kecamatan',
            'responsive': ['md'],
        },
        {
            'title': 'Kelurahan',
            'dataIndex': 'kelurahan',
            'key': 'kelurahan',
            'responsive': ['md'],
        },
        // {
        //     'title': 'Kode Pos',
        //     'dataIndex': 'kode_pos',
        //     'key': 'kode_pos',
        //     'responsive': ['md'],
        // },
        {
            'title': 'No Telp',
            'dataIndex': 'no_telp',
            'key': 'no_telp',
            'responsive': ['md'],
        },
        // {
        //     'title': 'Email',
        //     'dataIndex': 'email',
        //     'key': 'email',
        //     'responsive': ['md'],
        // },
        {
            'title': 'Website',
            'dataIndex': 'website',
            'key': 'website',
            'responsive': ['md'],
        },
    ]

    const columnsMasyarakat = [
        {
            'title': 'Nama',
            'dataIndex': 'nama',
            'key': 'nama',
            'responsive': ['md'],
        },
        {
            'title': 'Identitas',
            'dataIndex': 'identitas',
            'key': 'identitas',
            'responsive': ['md'],
        },
        {
            'title': 'No HP',
            'dataIndex': 'no_hp',
            'key': 'no_hp',
            'responsive': ['md'],
        },
        {
            'title': 'Jenis Kelamin',
            'dataIndex': 'jenis_kelamin',
            'key': 'jenis_kelamin',
            'responsive': ['md'],
        },
        {
            'title': 'Alamat',
            'dataIndex': 'alamat',
            'key': 'alamat',
            'responsive': ['md'],
        },
    ]


    const getPengembang = async (q = "") => {
        await axios(
            {
                method: 'get',
                url: API.HOUSING_CLINIC + 'pengembang',
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${localStorage.getItem("housingtoken")}`,
                },
                params: {
                    q: q
                }
            }
        ).then((data) => {
            console.log(data.data.data);
            setDataPengembang(data.data.data);
        }).catch((err) => {
            console.log(err);
        });
    }

    const getMasyarakat = async () => {
        await axios(
            {
                method: 'get',
                url: API.HOUSING_CLINIC + 'masyarakat',
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${localStorage.getItem("housingtoken")}`,
                },
            }
        ).then((data) => {
            console.log(data.data.data);
            setDataMasyarakat(data.data.data);
        }).catch((err) => {
            console.log(err);
        });
    }

    const getLokasi = async () => {
        await axios(
            {
                method: 'get',
                url: API.HOUSING_CLINIC + 'lokasi',
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${localStorage.getItem("housingtoken")}`,
                },
            }
        ).then((data) => {
            console.log(data.data.data);
            setDataLokasi(data.data.data);
        }).catch((err) => {
            console.log(err);
        });
    }

    useEffect(() => {
        getPengembang();
        getMasyarakat();
        getLokasi();
    }, []);

    return (
        <>
            <Table
                key={`pengembang`}
                showHeader={true}
                bordered={true}
                columns={columns}
                title={() => <h3>Pengembang</h3>}
                dataSource={
                    dataPengembang.map((item, index) => ({
                        key: index,
                        nama: item.nama,
                        alamat: item.alamat,
                        latitude: item.latitude,
                        longitude: item.longitude,
                        provinsi: item.provinsi,
                        kabupaten: item.kabupaten,
                        kecamatan: item.kecamatan,
                        kelurahan: item.kelurahan,
                        kode_pos: item.kode_pos,
                        no_telp: item.no_telp,
                        email: item.email,
                        website: item.website,
                    }))
                }
            />
            <Table
                key={`masyarakat`}
                showHeader={true}
                bordered={true}
                columns={columnsMasyarakat}
                title={() => <h3>Masyarakat</h3>}
                dataSource={
                    dataMasyarakat.map((item, index) => ({
                        key: index,
                        nama: item.nama,
                        identitas: item.identitas,
                        no_hp: item.no_hp,
                        jenis_kelamin: item.jenis_kelamin,
                        alamat: item.alamat,
                    }))
                }
            />
            <h3> Data Lokasi</h3>
            <Row className="rowgap-vbox" gutter={[24, 0]}>
                {dataLokasi.map((item, index) => {
                    return (
                        <Col
                            key={index}
                            xs={24}
                            sm={24}
                            md={12}
                            lg={12}
                            xl={12}
                            className="mb-24"
                        >
                            <Badge.Ribbon text="Sumber: HOUSING CLINIC" color="cyan">
                                <Card bordered={true}>
                                    <Row align="middle" gutter={[24, 0]}>
                                        <Col xs={24}>
                                            <p>
                                                Nama Perumahan: {item.nama_perumahan}
                                            </p>
                                        </Col>
                                    </Row>
                                    {/* "nama_perumahan": "Nama21",
                                    "tipe_rumah": "Tipe 54",
                                    "alamat": "Alamat",
                                    "latitude": -4.193029605360747,
                                    "longitude": 103.88671875000001,
                                    "provinsi": "SUMATERA SELATAN",
                                    "kabupaten": "KAB. OGAN KOMERING ULU",
                                    "kecamatan": "Baturaja Timur",
                                    "kode_pos": "1234", */}
                                    <p>
                                        Tipe: {item.tipe_rumah}
                                    </p>
                                    <p>
                                        Dikunjungi: {item.pengunjung}
                                    </p>
                                    <p>
                                        Pengembang: {item.pengembang}
                                    </p>
                                    <Alert message={"Jenis: "+item.jenis} type="success" />
                                    {item.foto.length > 0 ? (
                                        <Carousel autoplay>
                                            {item.foto.map((foto, index) => {
                                                return (
                                                    <div key={index} >
                                                        <img src={foto.foto} style={{ width: '100%', height: '350px', objectFit: 'cover' }} />
                                                    </div>
                                                )
                                            })}
                                        </Carousel>
                                    ) : (
                                        <Empty style={{ height: '350px' }} />
                                    )}
                                    <Alert message={item.latitude+", "+item.longitude} type="info" />
                                    <br />
                                    <p>
                                        Alamat: {item.alamat}
                                    </p>
                                    <p>
                                        {item.provinsi}, {item.kabupaten}, {item.kecamatan}, {item.kode_pos}
                                    </p>
                                    <p>
                                        Luas Perumahan: {item.luas_perumahan} m<sup>2</sup>
                                    </p>
                                    <p>
                                        Luas Tanah: {item.luas_tanah} m<sup>2</sup>
                                    </p>
                                    <p>
                                        Luas Bangunan: {item.luas_bangunan} m<sup>2</sup>
                                    </p>
                                    <p>
                                        Jumlah Unit: {item.jumlah_unit}
                                    </p>
                                    <p>
                                        Jumlah Kamar Tidur: {item.jumlah_kamar_tidur}
                                    </p>
                                    <p>
                                        Jumlah Kamar Mandi: {item.jumlah_kamar_mandi}
                                    </p>
                                    <p>
                                        Jumlah Lantai: {item.jumlah_lantai}
                                    </p>
                                    <p>
                                        Spesifikasi Atap: {item.spesifikasi_atap}
                                    </p>
                                    <p>
                                        Spesifikasi Dinding: {item.spesifikasi_dinding}
                                    </p>
                                    <p>
                                        Spesifikasi Lantai: {item.spesifikasi_lantai}
                                    </p>
                                    <p>
                                        Spesifikasi Plafon: {item.spesifikasi_plafon}
                                    </p>
                                </Card>
                            </Badge.Ribbon>
                        </Col>
                    );
                }
                )}
            </Row>
        </>
    )
}

export default HousingPage;