import ReactApexChart from "react-apexcharts";
function TotalBangubChart({total}) {
  let max = total.filter((item) => {
    return item.total === Math.max(...total.map((item) => item.total));
  });
  let labels = total.map((item) => {
    return item.kab_kota;
  });
  let series = total.map((item) => {
    return item['total'];
  });

  let barChart = {
    series: [{
      name: 'Total Bangub',
      data: series
    }],
    options: {
      annotations: total.length > 0 ? {
        points: [{
          x: max[0].kab_kota,
          seriesIndex: 0,
          label: {
            borderColor: '#775DD0',
            offsetY: 0,
            style: {
              color: '#fff',
              background: '#775DD0',
            },
            text: `${max[0].kab_kota}`,
          }
        }]
      }: {},
      chart: {
        height: 350,
        type: 'bar',
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          columnWidth: '50%',
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: 2
      },
      
      grid: {
        row: {
          colors: ['#fff', '#f2f2f2']
        }
      },
      xaxis: {
        labels: {
          rotate: -60
        },
        categories: labels,
        tickPlacement: 'on'
      },
      yaxis: {
        title: {
          text: 'Total Bangub',
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          type: "horizontal",
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 0.85,
          opacityTo: 0.85,
          stops: [50, 0, 100]
        },
      }
    },
  };

  return (
    <>
      <ReactApexChart
        options={barChart.options}
        series={barChart.series}
        type="bar"
        height={500}
      />
    </>
  );
}

export default TotalBangubChart;