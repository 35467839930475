import { useEffect, useState } from "react";
import axios from "axios";
import API from "../api/url";
import { PerkimLogin } from "../resource/res";
import { Table, Tag } from "antd";

const SupplyRumah = () => {
  const [rumahJual, setRumahJual] = useState([]);
  const [rumahDisewakan, setRumahDisewakan] = useState([]);

  const columns = [
    {
      title: 'PROVINSI',
      dataIndex: 'provinsi',
      key: 'provinsi',
    },
    {
      title: 'KABUPATEN',
      dataIndex: 'kabupaten',
      key: 'kabupaten',
    },
    {
      title: 'TIPE KECIL',
      dataIndex: 'tipe_kecil',
      key: 'tipe_kecil',
      render: (text) => (
        <Tag color="volcano" key={`tipe_kecil_${text}`}>
          {text}
        </Tag>
      ),
      sorter: (a, b) => a.tipe_kecil - b.tipe_kecil,
    },
    {
      title: 'TIPE SEDANG',
      dataIndex: 'tipe_sedang',
      key: 'tipe_sedang',
      render: (text) => (
        <Tag color="magenta" key={`tipe_sedang_${text}`}>
          {text}
        </Tag>
      ),
      sorter: (a, b) => a.tipe_sedang - b.tipe_sedang,
    },
    {
      title: 'TIPE BESAR',
      dataIndex: 'tipe_besar',
      key: 'tipe_besar',
      render: (text) => (
        <Tag color="geekblue" key={`tipe_besar_${text}`}>
          {text}
        </Tag>
      ),
      sorter: (a, b) => a.tipe_besar - b.tipe_besar,
    },
    {
      title: 'TOTAL',
      dataIndex: 'total',
      key: 'total',
      render: (text) => (
        <Tag color="#108ee9" key={`total_${text}`}>
          {text}
        </Tag>
      ),
      sorter: (a, b) => a.total - b.total,
    },
  ];

  const getRumahJual = async () => {
    await axios({
      method: "get",
      url: `${API.JS_PERKIM}rumah-jual-rekap`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("jstoken")}`,
      },
    }).then((response) => {
      const { data } = response.data;
      setRumahJual(data);
      console.log(rumahJual.length);
    }).catch((error) => {
      console.log(error.response);
      if (error.response.status == 401) {
        PerkimLogin();
        getRumahJual();
      }
    });
  };

  const getRumahDisewakan = async () => {
    await axios({
      method: "get",
      url: `${API.JS_PERKIM}rumah-disewakan`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("jstoken")}`,
      },
    }).then((response) => {
      const { data } = response.data;
      setRumahDisewakan(data);
    }).catch((error) => {
      console.log(error.response);
      if (error.response.status == 401) {
        PerkimLogin();
        getRumahDisewakan();
      }
    });
  };

  useEffect(() => {
    getRumahJual();
    getRumahDisewakan();

  }, []);

  return (
    <>
      <Table
        key={`rumah_jual`}
        showHeader={true} 
        bordered={true} 
        columns={columns} 
        title={() => <h3>Rekap Rumah Jual</h3>}
        dataSource={
          rumahJual.map((item, index) => ({
            key: index,
            provinsi: item.provinsi,
            kabupaten: item.kabupaten,
            tipe_kecil: item.tipe_kecil,
            tipe_sedang: item.tipe_sedang,
            tipe_besar: item.tipe_besar,
            total: item.total,
          }))
        } 
      />
      <Table
        key={`rumah_disewakan`}
        showHeader={true} 
        bordered={true} 
        columns={columns} 
        title={() => <h3>Rumah Disewakan</h3>}
        dataSource={
          rumahDisewakan.map((item, index) => ({
            key: index,
            provinsi: item.provinsi,
            kabupaten: item.kabupaten,
            tipe_kecil: item.tipe_kecil,
            tipe_sedang: item.tipe_sedang,
            tipe_besar: item.tipe_besar,
            total: item.total,
          }))
        }
      />
    </>
  );
};

export default SupplyRumah;
