import { useState, useEffect } from "react";
import TotalDashboard from "./DashboardTotal";
import { Card, Col, Row } from "antd";
import axios from "axios";
import API from "../api/url";
import DevChart from "../components/chart/DevChart";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const RekapPerum = () => {
  const [dev, setDev] = useState([]);
  useEffect(() => {
    axios.post(`${API.RUMAH_WONG_KITO}public_dev/data_kokab`)
    .then((response) => {
      const data = response.data.data;
      setDev(data);
    })
    .catch(error => console.log(`Error: ${error}`));
  }, []);
  return (
    <>
      <TotalDashboard />
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
          <Card bordered={false} className="criclebox h-full">
            {dev.length > 0 ? (
              <DevChart dev={dev} />
            ) : (
              <SkeletonTheme height={550}>
                <p>
                  <Skeleton />
                </p>
              </SkeletonTheme>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default RekapPerum;
