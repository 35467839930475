import axios from "axios";
import API from "../api/url";
import { AES } from "crypto-js";
import RESOURCE from "../api/var";

export const PerkimLogin = async () => {
    const AUTHDATA = {
        user: "admin@admin.com",
        password: "secret",
    };

    let formData = new FormData();
    formData.append("email", AUTHDATA.user);
    formData.append("password", AUTHDATA.password);
    await axios({
        method: "post",
        url: `${API.JS_PERKIM}auth`,
        data: formData,
        headers: {
        Accept: "application/json",
        },
    }).then((response) => {
        const { token } = response.data;
        localStorage.setItem("jstoken", token);
    }).catch((error) => {
        localStorage.setItem("jstoken", "");
    });

};

export const HousingLogin = async () => {
    const enc = AES.encrypt(`${RESOURCE.USER}:${RESOURCE.PASS}`, RESOURCE.KEY).toString();
    await axios(
        {
            method: 'post',
            url: API.HOUSING_CLINIC + 'login',
            data: {
                data: enc
            }
        }
    ).then((response) => {
        const { token } = response.data;
        console.log(token);
        localStorage.setItem("housingtoken", token);
    }).catch((err) => {
        localStorage.setItem("housingtoken", "");
    });
}

// export default PerkimLogin;
