import { useEffect, useState } from "react";
import { Card, Col, Row, Typography, Table, Space, Select, Input, message, Button, Carousel, Image, Empty, Descriptions } from "antd";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import axios from "axios";
import API from "../api/url";
import TotalDashboard from "./DashboardTotal";
import Paragraph from "antd/lib/typography/Paragraph";

const Perumahan = () => {
  const [searchedColumn, setSearchedColumn] = useState('');
  const [loading, setLoading] = useState(false);
  const [perum, setPerum] = useState([]);
  const [perumFilter, setPerumFilter] = useState([]);
  const [detailPerum, setDetailPerum] = useState([]);
  const [detailPerumImage, setDetailPerumImage] = useState([]);
  const { Title, Text } = Typography;
  const { Search } = Input;
  const { Option } = Select;

  const contentStyle = {
    height: '250px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
  };

  const onClickDetail = (v) => {
    getDetailPerumahan(v);
  }

  const getDetailPerumahan = (v) => {
    axios({
      method: 'post',
      url: `${API.RUMAH_WONG_KITO}public_map/data_perumahan_detail`,
      data: `id=${v}`,
      headers: { "content-type": "application/x-www-form-urlencoded" },
    })
    .then((response) => {
      if(response.data.length > 0) {
        const data = response.data[0];
        setDetailPerum(data);
      } else {
        setDetailPerum(null);
      }
    })
    .catch(error => console.log(`Error: ${error}`));

    axios({
      method: 'post',
      url: `${API.RUMAH_WONG_KITO}public_map/get_foto`,
      data: `id=${v}`,
      headers: { "content-type": "application/x-www-form-urlencoded" },
    })
    .then((response) => {
      if(response.data.length > 0) {
        const data = response.data;
        setDetailPerumImage(data);
      } else {
        setDetailPerumImage([]);
      }
    })
    .catch(error => console.log(`Error: ${error}`));
  };

  const onSearch = value => {
    setLoading(true);
    if(value != ''){
      if(searchedColumn == '') {
        message.error('Pilih kolom pencarian terlebih dahulu', 1);
        setLoading(false);
      } else {
        const filteredData = perum.filter(entry => entry[searchedColumn].toLowerCase().includes(value.toLowerCase()));
        setPerumFilter(filteredData);
        setLoading(false);
      }
    }else{
      setPerumFilter(perum);
      setLoading(false);
    }
  };

  const onSearchHandling = value => {
    setLoading(true);
    if(value != ''){
      if(searchedColumn == '') {
        message.error('Pilih kolom pencarian terlebih dahulu', 1);
        setLoading(false);
      } else {
        const filteredData = perum.filter(entry => entry[searchedColumn].toLowerCase().includes(value.toLowerCase()));
        setPerumFilter(filteredData);
        setLoading(false);
      }
    }else{
      setPerumFilter(perum);
      setLoading(false);
    }
  };

  const handleChange = (value) => {
    setSearchedColumn(value);
  };

  const columns = [
    {
      title: "NAMA DEVELOPER",
      dataIndex: "dev_name",
      key: "dev_name",
    },
    {
      title: "NAMA PERUMAHAN",
      dataIndex: "perum_name",
      key: "perum_name",
    },
    {
      title: "ALAMAT",
      key: "perum_address",
      dataIndex: "perum_address",
    },
    {
      title: "KEC",
      key: "kec_id",
      dataIndex: "kec_id",
    },
    {
      title: "KAB/KOTA",
      key: "kokab_id",
      dataIndex: "kokab_id",
    },
    {
      title: "TIPE",
      key: "type",
      dataIndex: "type",
    },
    {
      title: "DETAIL",
      key: "detail",
      dataIndex: "detail",
      render: (text, record) => (
        <Button type="primary" size="small" onClick={() => onClickDetail(record.perum_id)}>Detail</Button>
      ),
    }
  ];

  useEffect(() => {
    axios.post(`${API.RUMAH_WONG_KITO}public_perumlist/ajax_data`)
    .then((response) => {
      const data = response.data.data;
      setPerum(data);
      setPerumFilter(data);
    })
    .catch(error => console.log(`Error: ${error}`));
  }, []);
  return (
    <>
      <TotalDashboard />
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={16} className="mb-24">
          <Card bordered={false} className="criclebox cardbody h-full">
            <div className="project-ant">
              <div>
                <Title level={5}>Daftar Perumahan</Title>
                <Paragraph className="lastweek">
                  Sumber Data : <span className="blue">Rumah Wong Kito</span>
                </Paragraph>
              </div>
              <div className="ant-filtertabs">
                <div className="antd-pro-pages-dashboard-analysis-style-salesExtra">
                  {/* <Radio.Group onChange={onChange} defaultValue="a">
                      <Radio.Button value="a">ALL</Radio.Button>
                      <Radio.Button value="b">ONLINE</Radio.Button>
                      <Radio.Button value="c">STORES</Radio.Button>
                    </Radio.Group> */}
                  <Space>
                    <Space direction="vertical">
                      <Select
                        bordered={true}
                        size="large"
                        style={{
                          width: 150,
                        }}
                        placeholder="Pilih Kolom"
                        onChange={handleChange}
                      >
                        {columns.map((c, index) => (
                          <Option key={c.key} value={c.key}>
                            {c.title}
                          </Option>
                        ))}
                      </Select>
                    </Space>

                    <Search
                      style={{ width: 200 }}
                      placeholder="Search"
                      onSearch={onSearch}
                      onChange={(e) => onSearchHandling(e.target.value)}
                      enterButton
                    />
                  </Space>
                </div>
              </div>
            </div>
            <div className="ant-list-box table-responsive">
              <div style={{ padding: "20px" }}>
                {perum.length > 0 ? (
                  <Table
                    bordered={true}
                    columns={columns}
                    dataSource={perumFilter}
                    size="small"
                    loading={loading}
                    pagination={true}
                    rowKey="perum_id"
                    className="ant-border-space"
                  />
                ) : (
                  <SkeletonTheme height={550}>
                    <p>
                      <Skeleton />
                    </p>
                  </SkeletonTheme>
                )}
              </div>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={8} className="mb-24">
          <Card bordered={false} className="criclebox h-full">
            <div>
              <Title level={5}>Informasi Detail Perumahan</Title>
              <Paragraph className="lastweek">
                Sumber Data : <span className="blue">Rumah Wong Kito</span>
              </Paragraph>
            </div>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Carousel autoplay>
                  {detailPerumImage.length > 0 ? (
                    detailPerumImage.map((c, index) => (
                      <div key={c.image_id}>
                        {/* <h3 style={contentStyle}>1</h3> */}
                        <Image
                          style={contentStyle}
                          width="100%"
                          src={`${API.RUMAH_WONG_KITO_IMAGE}${c.image_name}`}
                        />
                      </div>
                    ))
                  ) : (
                    <div>
                      <Empty />
                    </div>
                  )}
                </Carousel>
              </Col>
              <Col span={24}>
                <Card className="card-billing-info" bordered="false">
                  <div className="col-info">
                    <Descriptions title="DETAIL PERUMAHAN">
                      <Descriptions.Item label="Nama Perumahan" span={3}>
                        {detailPerum == null ? "-" : detailPerum.perum_name}
                      </Descriptions.Item>
                      <Descriptions.Item label="Alamat" span={3}>
                        {detailPerum == null ? "-" : detailPerum.perum_address}
                      </Descriptions.Item>
                      <Descriptions.Item label="Developer" span={3}>
                        {detailPerum == null ? "-" : detailPerum.dev_id}
                      </Descriptions.Item>
                      <Descriptions.Item label="Kecamatan" span={3}>
                        {detailPerum == null ? "-" : detailPerum.kec_id}
                      </Descriptions.Item>
                      <Descriptions.Item label="Koordinat" span={3}>
                        {detailPerum == null ? "-" : detailPerum.koordinat}
                      </Descriptions.Item>
                      <Descriptions.Item label="Luas Lahan" span={3}>
                        {detailPerum == null ? "-" : detailPerum.luas_lahan}
                      </Descriptions.Item>
                      <Descriptions.Item label="Type" span={3}>
                        {detailPerum == null ? "-" : detailPerum.type}
                      </Descriptions.Item>
                      <Descriptions.Item label="Terjual" span={3}>
                        {detailPerum == null ? "-" : detailPerum.terjual}
                      </Descriptions.Item>
                      <Descriptions.Item label="Belum Terjual" span={3}>
                        {detailPerum == null ? "-" : detailPerum.belum_terjual}
                      </Descriptions.Item>
                      <Descriptions.Item label="Belum Terbangun" span={3}>
                        {detailPerum == null
                          ? "-"
                          : detailPerum.belum_terbangun}
                      </Descriptions.Item>
                      <Descriptions.Item label="Sisa Rumah" span={3}>
                        {detailPerum == null ? "-" : detailPerum.sisa_rumah}
                      </Descriptions.Item>
                    </Descriptions>
                  </div>
                  {/* <div className="col-action">
                      <Button type="link" danger>
                        {deletebtn}DELETE
                      </Button>
                      <Button type="link" className="darkbtn">
                        {pencil} EDIT
                      </Button>
                    </div> */}
                </Card>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Perumahan;
