import { useState, useEffect } from "react";
import TotalDashboard from "./DashboardTotal";
import { Card, Col, Row } from "antd";
import Echart from "../components/chart/EChart";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import axios from "axios";
import API from "../api/url";

const RtlhBacklog = () => {
	const [rtlh, setRtlh] = useState([]);
	const [backlog, setBacklog] = useState([]);

	useEffect(() => {
		axios.post(`${API.RUMAH_WONG_KITO}public_rtlh/data_kokab`)
    .then((response) => {
      const data = response.data.data;
      setRtlh(data);
    })
    .catch(error => console.log(`Error: ${error}`));

    axios.post(`${API.RUMAH_WONG_KITO}public_backlog/data_kokab`)
    .then((response) => {
      const data = response.data.data;
      setBacklog(data);
    })
    .catch(error => console.log(`Error: ${error}`));
	}, []);
  return (
		<>
			<TotalDashboard />
			<Row gutter={[24, 0]}>
				<Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
					<Card bordered={false} className="criclebox h-full">
						{rtlh.length > 0 ? <Echart rtlh={rtlh} backlog={backlog} /> : <SkeletonTheme height={550}>
						<p>
								<Skeleton />
						</p>
						</SkeletonTheme>}
					</Card>
				</Col>
			</Row>
		</>
  );
};

export default RtlhBacklog;
