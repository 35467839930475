let eChart = {
  series: [
    {
      name: "Sales",
      data: [450, 200, 100, 220, 500, 100, 400, 230, 500],
    },
  ],

  options: {
    chart: {
      type: "bar",
      width: "100%",
      height: "auto",
      toolbar: {
        show: false,
      },
      background: undefined,
    },
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        distributed: false,
        columnWidth: "100%",
        // borderRadius: 5,
        dataLabels: {
          position: 'bottom'
        },
      },
    },
    dataLabels: {
      enabled: false,
      textAnchor: 'start',
      style: {
        colors: ['#130076']
      },
      formatter: function (val, opt) {
        return val;
      },
      // offsetX: 0,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },
    grid: {
      show: true,
      borderColor: "#ccc",
      strokeDashArray: 2,
    },
    title: {
      text: "Data RTLH | Data Backlog",
      align: 'center',
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize:  '14px',
        fontWeight:  'bold',
        color:  '#000'
      },
    },
    subtitle: {
      text: "Sumber data : Rumah Wong Kito",
      align: 'center',
      margin: 15,
      offsetX: 0,
      offsetY: 20,
      floating: false,
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        fontFamily: undefined,
        color:  '#0900ff9e'
      },
    },
    xaxis: {
      categories: [
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
      ],
      labels: {
        show: true,
        align: "right",
        minWidth: 0,
        maxWidth: 180,
        style: {
          colors: [
            "#000",
            "#000",
            "#000",
            "#000",
            "#000",
            "#000",
            "#000",
            "#000",
            "#000",
            "#000",
          ],
        },
      },
    },
    yaxis: {
      labels: {
        show: true,
        align: "right",
        minWidth: 0,
        maxWidth: 180,
        style: {
          fontSize: '10px',
          colors: [
            "#000",
            "#000",
            "#000",
            "#000",
            "#000",
            "#000",
            "#000",
            "#000",
            "#000",
            "#000",
          ],
          fontWeight: 'bold',
        },
      },
    },
    // tooltip: {
    //   y: {
    //     formatter: function (val) {
    //       return val;
    //     },
    //   },
    // },
  },
};

export default eChart;
