import { useEffect, useState } from "react";

import {
  Card,
  Col,
  Row,
  Table,
  Space,
	Input,
  Typography,
  Form, 
  Switch,
  Button,
  Tag,
  message,
  Tooltip,
  Popconfirm
} from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { 
  InfoCircleOutlined, 
  QuestionCircleOutlined, 
  CheckOutlined, 
  CloseOutlined, 
  PoweroffOutlined, 
  SyncOutlined, 
  DeleteOutlined 
} from '@ant-design/icons';
import API from "../api/url";
import axios from "axios";
import { useHistory } from "react-router-dom";

const Koneksi = () => {
	const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [save, setSave] = useState(false);
  const [destroy, setDestroy] = useState(false);
	const [data, setData] = useState([]);
  const [dataFilter, setDataFilter] = useState([]);
  const [id, setId] = useState("");

  const [form] = Form.useForm();
	const { Search } = Input;
	const { Title, Text } = Typography;
  const history = useHistory();

	const onSearchHandling = value => {
		setDataFilter(data.filter(item => item.nama_website.toLowerCase().includes(value.toLowerCase())));
  };

  const getData = async () => {
    await axios.get(`${API.DISPERKIM_UTAMA}website/data`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
      }
    })
    .then((response) => {
      const data = response.data.data;
      setData(data);
      setDataFilter(data);
    })
    .catch(error => console.log(`Error: ${error}`));
  };

  const onSave = async (data) => {
    setSave(true);
    if(id === "") {
      await axios({
        method: 'post',
        url: `${API.DISPERKIM_UTAMA}website/data`,
        data,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("token")}`,
        }
      })
      .then((response) => {
        const data = response.data.data;
        onReset();
        setData(data);
        setDataFilter(data);
      })
      .catch(error => console.log(`Error: ${error}`));
    } else {
      await axios({
        method: 'put',
        // method: "post",
        url: `${API.DISPERKIM_UTAMA}website/data/${id}`,
        data,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("token")}`,
        }
      })
      .then((response) => {
        const data = response.data.data;
        onReset();
        setData(data);
        setDataFilter(data);
      })
      .catch(error => console.log(`Error: ${error}`));
    }
    setSave(false);
  };

  const onDelete = async (id) => {
    setDestroy(true);
    setSave(true);
    await axios({
      method: 'delete',
      // method: 'post',
      url: `${API.DISPERKIM_UTAMA}website/data/${id}`,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
      }
    })
    .then((response) => {
      const data = response.data.data;
      message.success('Data berhasil dihapus');
      onReset();
      setData(data);
      setDataFilter(data);
    })
    .catch(error => console.log(`Error: ${error}`));
    setDestroy(false);
    setSave(false);
  };

  const validateMessages = {
    required: '${label} tidak boleh kosong!',
    types: {url: '${label} tidak valid!',}
  };
  let dataUser = null;
  if(localStorage.getItem("user") !== "") {
    dataUser = JSON.parse(localStorage.getItem("user"));
  } else {
    history.replace("/sign-in");
  }

  const onFinish = (values) => {
    console.log(values);
    if(dataUser !== null) { 
      values.data.user_id = dataUser.id;
      // data.filter((item) => item.url === values.data.url).length > 0 ? message.error('Url Website sudah ada!') : setData([...data, values.data]);
      if(id === "") {
        data.filter((item) => item.url === values.data.url).length > 0 ? message.error('Url Website sudah ada!') : onSave(values.data);
      } else {
        onSave(values.data);
      }
    } else {
      history.replace("/sign-in");
    }
  };

  const onRefresh = async (e) => {
    e.preventDefault();
    setRefresh(true);
    await getData();
    setRefresh(false);
  };

  const confirm = async (e) => {
    e.preventDefault();
    await onDelete(id);
  };

  const onReset = () => {
    setId("");
    form.resetFields();
  };

	useEffect(() => {
		setLoading(true);
    getData();
    setLoading(false);
	}, []);

  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={14} xl={14} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <div className="project-ant">
                <div>
                  <Title level={5}>Daftar Link Website</Title>
                  <Paragraph className="lastweek">
                    Note :<span className="red">Ini hanya merupakan link dan bukan sumber data</span>
                  </Paragraph>
                </div>
                <div className="ant-filtertabs">
                  <div className="antd-pro-pages-dashboard-analysis-style-salesExtra">
                    <Space>
                      <Search
                        placeholder="Search"
                        onChange={(e) => onSearchHandling(e.target.value)}
                        enterButton
                      />
                    </Space>
                  </div>
                </div>
              </div>
              <div className="project-ant">
                <Tooltip title="refresh">
                  <Button type="primary" size={"small"} loading={refresh} onClick={e => onRefresh(e)} icon={<SyncOutlined />}>Refresh</Button>
                </Tooltip>
              </div>
              <div className="ant-list-box table-responsive">
                <div style={{ padding: "20px" }}>
                  {!loading ? (
                    <Table
                      bordered={true}
                      columns={[
                        {
                          title: "NAMA WEBSITE",
                          dataIndex: "nama_website",
                          key: "nama_website",
                        },
                        {
                          title: "URL",
                          dataIndex: "url",
                          key: "url",
                          render: (text) => (
                            <a href={text} target="_blank" rel="noreferrer">
                              {text}
                            </a>
                          ),
                        },
												{
                          title: "Status",
                          dataIndex: "is_aktif",
                          key: "is_aktif",
                          render: (_, record) => record.is_aktif ? (<Tag color="purple">On</Tag>) : (<Tag color="red">Off</Tag>),
                        },
                      ]}
                      dataSource={dataFilter}
                      size="small"
                      loading={loading}
                      pagination={true}
                      rowKey="id"
                      onRow={(record, rowIndex) => {
                        return {
                          onClick: event => {
                            setId(record.id);
                            form.setFieldsValue({
                              data: record,
                            });
                          },
                        };
                      }}
                      className="ant-border-space"
                    />
                  ) : (
                    <SkeletonTheme height={300}>
                      <p>
                        <Skeleton />
                      </p>
                    </SkeletonTheme>
                  )}
                </div>
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} lg={10} xl={10} className="mb-24">
            <Card bordered={false} className="criclebox h-full" title="Kelola Link Website">
              <div className="project-ant">
                <Form
                  form={form}
                  layout="vertical"
                  size="default"
                  validateMessages={validateMessages}
                  onFinish={onFinish}
                  initialValues={{ data: {is_aktif: false}}}
                >
                  <Form.Item
                    name={['data', 'nama_website']}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                    label="Nama Website" tooltip="Form ini wajib diisi">
                    <Input placeholder="Nama Website"/>
                  </Form.Item>
                  <Form.Item
                    name={['data', 'url']}
                    rules={[
                      {
                        required: true,
                      },
                      {
                        type: 'url',
                      }
                    ]}
                    label="URL" tooltip="Form ini wajib diisi"
                    // tooltip={{
                    //   title: 'Optional Form',
                    //   icon: <InfoCircleOutlined />,
                    // }}
                  >
                    <Input placeholder="URL"/>
                  </Form.Item>
                  <Form.Item
                    name={['data', 'is_aktif']}
                    label="Status Aktif"
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                  <Form.Item>
                    <Space size={5}>
                      <Button type="primary" size="small" loading={save} htmlType="submit">{id == "" ? "Simpan": "Update"}</Button>
                      <Button htmlType="button" onClick={onReset}>Reset</Button>
                      {
                        id != "" ? (
                        <Popconfirm
                          title="Are you sure to delete this task?"
                          onConfirm={confirm}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="primary" size="small" icon={<DeleteOutlined />} danger>Hapus</Button>
                        </Popconfirm>
                        ) : null
                      }
                    </Space>
                  </Form.Item>
                </Form>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Koneksi;
