let treemapChart = {
  series: [
    {
      data: [
        {
          x: 'New Delhi',
          y: 218
        },
        {
          x: 'Kolkata',
          y: 149
        },
        {
          x: 'Mumbai',
          y: 184
        },
        {
          x: 'Ahmedabad',
          y: 55
        },
        {
          x: 'Bangaluru',
          y: 84
        },
        {
          x: 'Pune',
          y: 31
        },
        {
          x: 'Chennai',
          y: 70
        },
        {
          x: 'Jaipur',
          y: 30
        },
        {
          x: 'Surat',
          y: 44
        },
        {
          x: 'Hyderabad',
          y: 68
        },
        {
          x: 'Lucknow',
          y: 28
        },
        {
          x: 'Indore',
          y: 19
        },
        {
          x: 'Kanpur',
          y: 29
        }
      ]
    }
  ],

  options: {
    legend: {
      show: false
    },
    chart: {
      height: 550,
      type: 'treemap',
      width: "100%",
      toolbar: {
        show: false,
      },
    },
    title: {
      text: 'Basic Treemap',
      align: 'center',
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize:  '14px',
        fontWeight:  'bold',
        color:  '#000'
      },
    },
    subtitle: {
      text: "Sumber data : Rumah Wong Kito",
      align: 'center',
      margin: 15,
      offsetX: 0,
      offsetY: 20,
      floating: false,
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        fontFamily: undefined,
        color:  '#0900ff9e'
      },
    },
    colors: [
      '#3B93A5',
      '#F7B844',
      '#ADD8C7',
      '#EC3C65',
      '#CDD7B6',
      '#C1F666',
      '#D43F97',
      '#1E5D8C',
      '#421243',
      '#7F94B0',
      '#EF6537',
      '#C0ADDB'
    ],
    plotOptions: {
      treemap: {
        distributed: true,
        enableShades: false
      }
    }
  }
};

export default treemapChart;