import { useEffect, useState } from "react";
import { Card, Col, Row, Typography } from "antd";
import {
  PieChartOutlined,
  CopyOutlined,
  UserOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import API from "../api/url";
import axios from 'axios';

const TotalDashboard = () => {
  const [perum, setPerum] = useState([]);
  const [perumFilter, setPerumFilter] = useState([]);
  const [totalDev, setTotalDev] = useState(0);
  const [totalAll, setTotalAll] = useState({
    backlog: 0,
    dev: 0,
    rtlh: 0,
  });

  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState('right');
  const showDrawer = () => {
    setOpen(true);
  };
  const onChange = (e) => {
    setPlacement(e.target.value);
  };
  const onClose = () => {
    setOpen(false);
  };

  const { Title } = Typography;

  useEffect(() => {
    axios.post(`${API.RUMAH_WONG_KITO}public_perumlist/ajax_data`)
    .then((response) => {
      const data = response.data.data;
      setPerum(data);
      setPerumFilter(data);
    })
    .catch(error => console.log(`Error: ${error}`));

    axios
      .post(`${API.RUMAH_WONG_KITO}public_dev/total_dev`)
      .then((response) => {
        const data = response.data.data.total;
        setTotalDev(data);
      })
      .catch((error) => console.log(`Error: ${error}`));

    axios
      .post(`${API.RUMAH_WONG_KITO}public_home/data_all`)
      .then((response) => {
        const data = response.data[0];
        setTotalAll(data);
      })
      .catch((error) => console.log(`Error: ${error}`));
  },[]);

  return (
    <Row className="rowgap-vbox" gutter={[24, 0]}>
      <Col
        // key={index}
        xs={24}
        sm={24}
        md={12}
        lg={6}
        xl={6}
        className="mb-24"
      >
        <Card bordered={false} className="criclebox ">
          <div className="number">
            <Row align="middle" gutter={[24, 0]}>
              <Col xs={18}>
                <span>Total Perumahan</span>
                <Title level={3}>{perum.length}</Title>
                <small>Sumber: </small>
                <small className="bnb2">Rumah Wong Kito</small>
              </Col>
              <Col xs={6}>
                <div className="icon-box">
                  <HomeOutlined />
                </div>
              </Col>
            </Row>
          </div>
        </Card>
      </Col>
      <Col
        // key={index}
        xs={24}
        sm={24}
        md={12}
        lg={6}
        xl={6}
        className="mb-24"
      >
        <Card bordered={false} className="criclebox ">
          <div className="number">
            <Row align="middle" gutter={[24, 0]}>
              <Col xs={18}>
                <span>Total Developer</span>
                <Title level={3}>{totalDev}</Title>
                <small>Sumber: </small>
                <small className="bnb2">Rumah Wong Kito</small>
              </Col>
              <Col xs={6}>
                <div className="icon-box">
                  <UserOutlined />
                </div>
              </Col>
            </Row>
          </div>
        </Card>
      </Col>
      <Col
        // key={index}
        xs={24}
        sm={24}
        md={12}
        lg={6}
        xl={6}
        className="mb-24"
      >
        <Card bordered={false} className="criclebox ">
          <div className="number">
            <Row align="middle" gutter={[24, 0]}>
              <Col xs={18}>
                <span>Total Backlog</span>
                <Title level={3}>{totalAll.backlog}</Title>
                <small>Sumber: </small>
                <small className="bnb2">Rumah Wong Kito</small>
              </Col>
              <Col xs={6}>
                <div className="icon-box">
                  <CopyOutlined />
                </div>
              </Col>
            </Row>
          </div>
        </Card>
      </Col>
      <Col
        // key={index}
        xs={24}
        sm={24}
        md={12}
        lg={6}
        xl={6}
        className="mb-24"
      >
        <Card bordered={false} className="criclebox ">
          <div className="number">
            <Row align="middle" gutter={[24, 0]}>
              <Col xs={18}>
                <span>Total RTLH</span>
                <Title level={3}>{totalAll.rtlh}</Title>
                <small>Sumber: </small>
                <small className="bnb2">Rumah Wong Kito</small>
              </Col>
              <Col xs={6}>
                <div className="icon-box">
                  <PieChartOutlined />
                </div>
              </Col>
            </Row>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default TotalDashboard;
