const API = {
    // RUMAH_WONG_KITO: 'https://www.rumahwongkito.id/administrator/api/',
    RUMAH_WONG_KITO: 'https://www.rumahwongkito.id/administrator/api/',
    RUMAH_WONG_KITO_IMAGE: 'https://www.rumahwongkito.id/administrator/assets/img/perumIMAGE/',
    DISPERKIM: 'http://localhost/pu-palembang-register/public/api/',
    DISPERKIM_UTAMA: 'https://simdapro.sumselprov.my.id/api/',
    // DISPERKIM_UTAMA: 'https://disperkim.host4.fran.id/api/',
    // DISPERKIM_UTAMA: 'http://localhost/pu-palembang/public/api/',
    DISPERKIM_BASE_URL: 'https://simdapro.sumselprov.my.id/',
    JS_PERKIM: 'https://api-js-perkim.sumselprov.my.id/api/',
    HOUSING_CLINIC_DEV: 'http://localhost:8000/api/',
    HOUSING_CLINIC: 'https://housing-clinic.sumselprov.my.id/api/',
};

export default API;