import ReactApexChart from "react-apexcharts";
import treemapChart from "./configs/treemapChart";

function DevChart({dev}) {
    const data = dev.map((item) => {
        return {
            x: item.kokab_name,
            y: item.totaldev.replace(',', ''),
        }
    });
    const series = [{
      name: 'Rekap Perumahan',
      data: data,
    }];

    treemapChart.options.title.text = 'Rekap Perumahan';
  
    treemapChart.series = series;
    return (
        <>
          <div id="chart-treemap">
            <ReactApexChart
              className=""
              options={treemapChart.options}
              series={treemapChart.series}
              type="treemap"
              height={550}
            />
          </div>
        </>
    );
}

export default DevChart;