import ReactApexChart from "react-apexcharts";
import pieChart from "./configs/pieChart";

function FlppChart({flpp}) {
  pieChart.options.title.text = 'Data Flpp';
  pieChart.options.subtitle.text = 'Sumber data : Rumah Wong Kito';

  pieChart.options.labels = flpp.map((item) => {
    return item.flpp_bank;
  });
  pieChart.series = flpp.map((item) => {
    return parseInt(item.flpp_unit);
  });
  // console.log('label', labels);
  // console.log('series', series);

  // pieChart.series = series;
  // pieChart.options.labels = labels;
  return (
    <>
      <ReactApexChart
        className=""
        options={pieChart.options}
        series={pieChart.series}
        type="donut"
        height={400}
        width={"100%"}
      />
    </>
  );
}

export default FlppChart;